import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  IconButton,
  Typography,
  Grid,
  tableCellClasses,
  InputAdornment,
  Dialog,

} from "@mui/material"; import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "components/Buttons";
import InputField from "components/Input";
import SelectField from "components/Select";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import styled from "@emotion/styled";
import Colors from "assets/Style/Colors";
import { EyeIcon, FontFamily, SearchIcon } from "assets";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useForm } from "react-hook-form";
import Pagination from "components/Pagination";
import VendorServices from "services/Vendor";
import { ErrorToaster, SuccessToaster } from "components/Toaster";
import { CommaSeparator, formatPermissionData } from "utils";
import DropdownMenu from "components/DropDown";
import { addPermission } from "redux/slices/navigationDataSlice";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { handleExportWithComponent } from "utils";
import { PDFExport } from '@progress/kendo-react-pdf';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from "@mui/material";

const Row = styled(TableRow)(({ theme }) => ({
  border: 0,
}));

const Cell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 14,
    fontFamily: FontFamily.NunitoRegular,
    border: 0,
    whiteSpace: "nowrap",
    background: Colors.primary,
    color: Colors.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: FontFamily.NunitoRegular,
    textAlign: "center",
    textWrap: "nowrap",
    padding: '5px !important',
    padding: '5px !important',

    ".MuiBox-root": {
      display: "flex",
      gap: "6px",
      alignItems: "center",
      justifyContent: "center",
      ".MuiBox-root": {
        cursor: "pointer",
      },
    },
    svg: {
      width: "auto",
      height: "24px",
    },
    ".MuiTypography-root": {
      textTransform: "capitalize",
      fontFamily: FontFamily.NunitoRegular,
      textWrap: "nowrap",
    },
    ".MuiButtonBase-root": {
      padding: "8px",
      width: "28px",
      height: "28px",
    },
  },
}));
const LoadingPlanList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // *For Pagination
  const [totalCount, setTotalCount] = useState(0);
  const [pageLimit, setPageLimit] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [loadingPort, setLoadingPort] = useState("");
  const [sourceLocation, setSourceLocation] = useState("");
  const [destination, setDestination] = useState("");
  const [towingVendor, setTowingVendor] = useState("");
  const [shippingVendor, setShippingVendor] = useState("");
  const [vendorPlans, setVendorPlans] = useState([])
  const [loader, setLoader] = useState(false);
  const [filters, setFilters] = useState({});
  const [activeMenu, setActiveMenu] = useState(null);
  const [planId, setPlanId] = useState(null)
  const [permissions, setPermissions] = useState();
  const contentRef = useRef(null);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [tableId, setTableId] = useState();
  const [dispatchFilter, setDispatchFilter] = useState("all");

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues
  } = useForm();

  const handleOpenDialog = (item) => {
    setTableId(item);
    setDialogOpen(true);
  };

  // Example options for the SelectField
  const loadingPortOptions = [
    { name: "Port A", id: "portA" },
    { name: "Port B", id: "portB" },
  ];
  const sourceLocationOptions = [
    { name: "Source 1", id: "source1" },
    { name: "Source 2", id: "source2" },
  ];
  const destinationOptions = [
    { name: "Destination 1", id: "destination1" },
    { name: "Destination 2", id: "destination2" },
  ];
  const towingVendorOptions = [
    { name: "Vendor 1", id: "vendor1" },
    { name: "Vendor 2", id: "vendor2" },
  ];
  const shippingVendorOptions = [
    { name: "Vendor A", id: "vendorA" },
    { name: "Vendor B", id: "vendorB" },
  ];
  const toggleRow = (referenceNo) => {
    setOpen((prev) => ({
      //   ...prev,
      [referenceNo]: !prev[referenceNo],
    }));
  };
  useEffect(() => {
    getVendorPlan()
  }, [])
  const getVendorPlan = async (page, limit, filter) => {
    setLoader(true);
    try {
      const Page = page || currentPage;
      const Limit = limit || pageLimit;
      const Filter = { ...filters, ...filter };

      setCurrentPage(Page);
      setPageLimit(Limit);
      setFilters(Filter);

      let params = { page: Page, limit: Limit, ...Filter };
      const { data } = await VendorServices.getCreatePlan(params);
      console.log(data, '.......data');

      // Grouping vendor plans by `booking_ref_no`
      const groupedPlans = data?.plans?.rows?.reduce((acc, plan) => {
        if (!acc[plan.booking_ref_no]) {
          acc[plan.booking_ref_no] = {
            booking_ref_no: plan.booking_ref_no,
            totalCost: parseFloat(plan.total_cost),
            suggested_ship_vendor: plan.suggested_ship_vendor,
            suggested_ship_vendor_id: plan?.suggested_ship_vendor_id,
            suggested_towing_vendor: plan.suggested_towing_vendor,
            suggested_towing_vendor_id: plan?.suggested_towing_vendor_id,
            towing_cost: plan.towing_cost,
            shipping_cost: plan.shipping_cost,
            container_no: plan?.container_no,
            created_at: plan?.created_at, // Ensure created_at is present
            id: plan?.id,
            vehicles: plan.vehicles || [],
            estimated_price: plan?.estimated_price,
          };
        }
        return acc;
      }, {});

      // Convert object to array and sort by `created_at` (latest first)
      const sortedPlans = Object.values(groupedPlans).sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      setPermissions(formatPermissionData(data?.permissions));
      data?.permissions.forEach((e) => {
        if (e?.route && e?.identifier && e?.permitted) {
          dispatch(addPermission(e?.route));
        }
      });
      setVendorPlans(sortedPlans);
      setTotalCount(data?.plans?.count);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoader(false);
    }
  };

  const applyFilter = async (value) => {
    try {
      let data = {
        search: getValues('search'),
      };
      getVendorPlan(1, '', data);
    } catch (error) {
      ErrorToaster(error);
    }
  }
  const handleMenuToggle = (menuId, isOpen) => {
    setActiveMenu(isOpen ? menuId : null); // Toggle the active menu
  };

  const deletePlan = async () => {
    try {
      let params = {
        plan_id: planId
      }
      const { data, message } = await VendorServices.deletePlan(params)
      getVendorPlan()
      handleClose()
      SuccessToaster(message)
    } catch (error) {
      ErrorToaster(error)
    }
  }
  const handleClose = () => {
    setOpenDeleteDialog(false);
  };

  const tableHead = [
    "Reference No.",
    "Container No.",
    "Towing Cost",
    "Shipping Cost",
    "Total Cost",
    "Action",
    "Customer",
    "Master ID",
    "Buyer ID",
    "VIN",
    "LOT",
    "Model",
    "Title",
    "Location",
    "Load Port",
    "GP Pin",
    "Off Site",
    "Destination",
    "Notes",
    "Status",
  ];

  const downloadExcel = (selectedRefNo) => {
    const headers = tableHead.filter((item) => item !== "Actions");
    const allRows = [];

    const selectedPlan = vendorPlans?.find((plan) => plan.booking_ref_no === selectedRefNo);

    if (selectedPlan) {
      selectedPlan?.vehicles?.forEach((vehicle) => {
        allRows.push([
          selectedPlan?.booking_ref_no,
          selectedPlan?.container_no,
          selectedPlan?.towing_cost,
          selectedPlan?.shipping_cost,
          selectedPlan?.totalCost,
          selectedPlan?.action || "-",
          vehicle?.customer?.name,
          vehicle?.booking?.buyer?.master_id,
          vehicle?.booking?.buyer?.name,
          vehicle?.vin,
          vehicle?.booking?.lot_number,
          vehicle?.veh_model?.name,
          vehicle?.booking?.title_status,
          vehicle?.source,
          vehicle?.loading_port,
          vehicle?.booking?.gp_pin,
          vehicle?.booking?.off_site,
          vehicle?.destination,
          vehicle?.dispatch_notes,
          vehicle?.dispatch_status
        ]);
      });
    }

    if (allRows.length === 0) {
      alert("No data available for the selected reference number.");
      return;
    }

    const ws = XLSX.utils.aoa_to_sheet([headers, ...allRows]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const buf = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
      mimeType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    saveAs(new Blob([buf]), "data.xlsx");
  };

  const handleFilterChange = (event) => {
    setDispatchFilter(event.target.value);
  };

  return (
    <Box sx={{ padding: "20px" }}>
      <Dialog
        open={openDeleteDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Typography sx={{ p: 3, px: 5, fontSize: 17, fontFamily: FontFamily.openSans, fontWeight: "bold" }} id="alert-dialog-title">{"Are You Sure You Want To Delete Plan?"}</Typography>

        <Grid item xs={12} sm={12} sx={{ mb: 2, textAlign: "center" }}>
          <PrimaryButton
            title="No"
            type="submit"
            buttonStyle={{ minWidth: "120px" }}
            onClick={() => { handleClose() }}

          />
          <Box component={"span"} sx={{ mx: 1 }} />
          <PrimaryButton
            title="Yes"
            type="button"
            buttonStyle={{
              minWidth: "120px",
              bgcolor: Colors.danger,
              "&:hover": { bgcolor: Colors.danger },
            }}
            bgcolor={Colors?.danger}
            onClick={() => { deletePlan() }}
          />
        </Grid>
      </Dialog>
      <Box
        sx={{
          display: "flex",
          gap: "11px",
          alignItems: "center",
          mb: 1,
          mt: 6,
        }}
      >

        <Typography
          variant="h5"
          sx={{
            color: Colors.charcoalGrey,
            fontFamily: FontFamily.NunitoRegular,
          }}
        >
          VAD Plan List
        </Typography>
      </Box>
      {/* Top Section */}
      <Box
        sx={{
          boxShadow: "0px 4px 4px 2px #00000040",
          p: 3,
          borderRadius: "12px",
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={3.5}>
            <InputField
              size={"small"}
              inputStyle={{ backgroundColor: '#f5f5f5' }}
              label={'Search'}
              InputProps={{
                startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
              }}
              placeholder={"Search"}
              register={register("search", {
                onChange: (e) => applyFilter(e?.target?.value)
              })}

            />
          </Grid>
          {/* <Grid item xs={12} sm={3}>
            <SelectField
              label={"Loading Port"}
              options={loadingPortOptions}
              selected={loadingPort}
              onSelect={(value) => setLoadingPort(value)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <SelectField
              label={"Source Location"}
              options={sourceLocationOptions}
              selected={sourceLocation}
              onSelect={(value) => setSourceLocation(value)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <SelectField
              label={"Destination"}
              options={destinationOptions}
              selected={destination}
              onSelect={(value) => setDestination(value)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <SelectField
              label={"Towing Vendor"}
              options={towingVendorOptions}
              selected={towingVendor}
              onSelect={(value) => setTowingVendor(value)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <SelectField
              label={"Shipping Vendor"}
              options={shippingVendorOptions}
              selected={shippingVendor}
              onSelect={(value) => setShippingVendor(value)}
            />
          </Grid> */}

          <Grid item xs={2} sm={2} mt={2} textAlign={"right"} gap={1}>
            {/* <PrimaryButton onClick={() => { applyFilter() }} title={"Search"} /> */}

          </Grid>

        </Grid>
        {/* //   <Grid item xs={12} sm={3}>
      //   <SelectField
      //     label={"Loading Port"}
      //     options={loadingPortOptions}
      //     selected={loadingPort}
      //     onSelect={(value) => setLoadingPort(value)}
      //   />
      // </Grid>
      // <Grid item xs={12} sm={3}>
      //   <SelectField
      //     label={"Source Location"}
      //     options={sourceLocationOptions}
      //     selected={sourceLocation}
      //     onSelect={(value) => setSourceLocation(value)}
      //   />
      // </Grid>
      // <Grid item xs={12} sm={3}>
      //   <SelectField
      //     label={"Destination"}
      //     options={destinationOptions}
      //     selected={destination}
      //     onSelect={(value) => setDestination(value)}
      //   />
      // </Grid>
      // <Grid item xs={12} sm={3}>
      //   <SelectField
      //     label={"Towing Vendor"}
      //     options={towingVendorOptions}
      //     selected={towingVendor}
      //     onSelect={(value) => setTowingVendor(value)}
      //   />
      // </Grid>
      // <Grid item xs={12} sm={3}>
      //   <SelectField
      //     label={"Shipping Vendor"}
      //     options={shippingVendorOptions}
      //     selected={shippingVendor}
      //     onSelect={(value) => setShippingVendor(value)}
      //   />
      // </Grid>
      //   <Grid item xs={12} sm={6} mt={2} textAlign={"right"}  gap={3}>
            
      //       <PrimaryButton  title={"Search"} />
          
      //   </Grid>
        
      //   </Grid> */}



      </Box>
      <Grid item md={11}>
        {/* {vendorPlans?.length > 0 && (
                    <Box
                      sx={{
                        textAlign: "right",
                        p: 4,
                        display: "flex",
                        gap: 2,
                        justifyContent: "flex-end",
                      }}
                    >
                      <PrimaryButton
                        title="Download PDF"
                        type="button"
                        style={{ backgroundColor: Colors.bluishCyan }}
                        onClick={() => handleExportWithComponent(contentRef)}
                      />
                      <PrimaryButton
                        title={"Download Excel"}
                        onClick={() => downloadExcel()}
                      />
                    </Box>
                  )} */}
      </Grid>


      <PDFExport
        ref={contentRef}
        landscape={true}
        paperSize="A4"
        margin={5}
        fileName="VAD Plans"
      >
        {/* Table Section */}
        <TableContainer style={{ marginTop: 25 }} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <Cell />
                <Cell style={{ textAlign: "center" }}>Reference No.</Cell>
                {/* <Cell style={{ textAlign: "center" }}>Customer</Cell> */}
                <Cell style={{ textAlign: "center" }}>Container No.</Cell>
                <Cell style={{ textAlign: "center" }}>Shipping Vendor</Cell>
                <Cell style={{ textAlign: "center" }}>Towing Vendor</Cell>
                <Cell style={{ textAlign: "center" }}>Towing Cost</Cell>
                <Cell style={{ textAlign: "center" }}>Shipping Cost</Cell>
                <Cell style={{ textAlign: "center" }}>Total Cost</Cell>
                <Cell style={{ textAlign: "center" }}>Action</Cell>

              </TableRow>
            </TableHead>
            <TableBody>
              {vendorPlans?.map((group, index) => (
                <React.Fragment key={index}>
                  {/* Parent Row - Summary of the Plan */}
                  <TableRow sx={{ bgcolor: index % 2 !== 0 && "#EFF8E7" }}>
                    <TableCell>
                      <IconButton
                        size="small"
                        onClick={() => toggleRow(group.booking_ref_no)}
                      >
                        {open[group.booking_ref_no] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                      </IconButton>
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>{group.booking_ref_no}</TableCell>
                    {/* <TableCell style={{ textAlign: "center" }}>{group.suggested_ship_vendor ?? "-"}</TableCell> */}
                    <TableCell style={{ textAlign: "center" }}>{group.container_no || "-"}</TableCell>
                    <TableCell style={{ textAlign: "center" }}>{group.suggested_ship_vendor || "-"}</TableCell>
                    <TableCell style={{ textAlign: "center" }}>{group.suggested_towing_vendor || "-"}</TableCell>
                    <TableCell style={{ textAlign: "center" }}>${CommaSeparator(parseFloat(group.towing_cost).toFixed(2))}</TableCell>
                    <TableCell style={{ textAlign: "center" }}>${CommaSeparator(parseFloat(group.shipping_cost).toFixed(2))}</TableCell>
                    <TableCell style={{ textAlign: "center" }}>${CommaSeparator(parseFloat(group.estimated_price * group?.vehicles?.length || 1).toFixed(2))}</TableCell>

                    <TableCell style={{ textAlign: "center" }}>
                      <Box
                        component={'div'}
                        className='pdf-hide'
                        sx={{ gap: '16px !important' }}
                      >
                        <DropdownMenu
                          item={group}
                          permissions={permissions}
                          navigate={navigate}
                          ErrorToaster={ErrorToaster}
                          Colors={Colors}
                          isOpen={activeMenu === group.id}
                          onToggle={(isOpen) => handleMenuToggle(group.id, isOpen)}
                          id={group?.id}
                          onEdit={() => {
                            navigate("/update-plan", { state: { plan: group } });

                          }}
                          vendorPlan={true}
                          // isAdmin={user?.user_type == 'A'}
                          onDeleteClick={() => {
                            setOpenDeleteDialog(true)
                            // handleMenuToggle(false)
                            // setOpenDeleteModal(true);
                            setPlanId(group?.id);
                          }}
                          onClick2={() => {
                            navigate(`/plan-detail/${group?.id}`, { state: { vehicles: group } });

                          }}
                          excelDownload={
                            () => downloadExcel(group.booking_ref_no)
                          }

                        />

                      </Box>
                    </TableCell>
                  </TableRow>

                  {/* Collapsible Row - Detailed Vehicles in the Plan */}
                  <TableRow>
                    <TableCell colSpan={9} style={{ paddingBottom: 0, paddingTop: 0 }}>
                      <Collapse in={open[group.booking_ref_no]} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                          <Box display="flex" gap={3}>
                            <Typography variant="h6" gutterBottom>
                              Vehicles ({group?.vehicles?.length})
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                              Dispatched Vehicles ({group?.vehicles?.filter((row) => row.dispatch_status).length})
                            </Typography>
                          </Box>
                          <FormControl component="fieldset">
                            <RadioGroup
                              row
                              value={dispatchFilter}
                              onChange={handleFilterChange}
                            >
                              <FormControlLabel value="all" control={<Radio />} label="All" />
                              <FormControlLabel value="dispatched" control={<Radio />} label="Dispatched" />
                              <FormControlLabel value="notDispatched" control={<Radio />} label="Not Dispatched" />
                            </RadioGroup>
                          </FormControl>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>Customer</TableCell>
                                <TableCell>Auctioneer</TableCell>
                                <TableCell>Master ID</TableCell>
                                <TableCell>Buyer ID</TableCell>
                                <TableCell>VIN</TableCell>
                                <TableCell>LOT</TableCell>
                                <TableCell>Model</TableCell>
                                <TableCell>Title</TableCell>
                                <TableCell>Location</TableCell>
                                <TableCell>Load Port</TableCell>
                                <TableCell>GP Pin</TableCell>
                                <TableCell>Off Site</TableCell>
                                <TableCell>Destination</TableCell>
                                <TableCell>Notes</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Veh.Status</TableCell>



                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {group?.vehicles
                                ?.filter((row) => {
                                  if (dispatchFilter === "notDispatched") return row.dispatch_status === false || row.dispatch_status === null;
                                  if (dispatchFilter === "dispatched") return row.dispatch_status === true;
                                  return true;
                                })
                                .map((row, rowIndex) => (
                                  <TableRow key={rowIndex} sx={{ bgcolor: rowIndex % 2 !== 0 && "#EFF8E7" }}>
                                    <TableCell>{row.customer?.name || "-"}</TableCell>
                                    <TableCell>{row.booking?.auctioneer || "-"}</TableCell>
                                    <TableCell>{row.booking?.buyer?.master_id || "-"}</TableCell>
                                    <TableCell>{row.booking?.buyer?.name || "-"}</TableCell>
                                    <TableCell>{row.vin || "-"}</TableCell>
                                    <TableCell>{row.lot_number || "-"}</TableCell>
                                    <TableCell>{row.veh_model?.name || "-"}</TableCell>
                                    <TableCell>{row.title_status || "-"}</TableCell>
                                    <TableCell>{row.source || "-"}</TableCell>
                                    <TableCell>{row.loading_port || "-"}</TableCell>
                                    <TableCell>{row.booking?.gp_pin || "-"}</TableCell>
                                    <TableCell>{row.booking?.off_site || "-"}</TableCell>
                                    <TableCell>{row.dest?.name || "-"}</TableCell>
                                    <TableCell>{row.dispatch_notes || "-"}</TableCell>
                                    <TableCell>{row.dispatch_status ? "Dispatched" : "Not Dispatched"}</TableCell>
                                    <TableCell>{Math.ceil(row?.booking?.invoice?.paid) === Math.ceil(row?.booking?.invoice?.amount) &&
                                      row?.booking?.invoice?.paid &&
                                      row?.booking?.invoice?.amount !== "0.000"
                                      ? "Paid"
                                      : Math.ceil(row?.booking?.invoice?.paid) === 0 ||
                                        Math.ceil(row?.booking?.invoice?.amount) === 0
                                        ? "UnPaid"
                                        : "Partial Paid"}</TableCell>

                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>

          </Table>
        </TableContainer>
      </PDFExport>
      {/* ========== Pagination ========== */}
      <Pagination
        currentPage={currentPage}
        pageSize={pageLimit}
        onPageSizeChange={(size) => { }}
        tableCount={0}
        totalCount={totalCount}
        onPageChange={(page) => { }}
      />
    </Box>
  );
};

export default LoadingPlanList;
