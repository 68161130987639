const routes = {
  getUnits: 'accounts/units',
  getMajorCategories: 'accounts/majorCategories',
  getSubCategories: 'accounts/subCategories',
  getAccountCode: 'accounts/code',
  getAccountBySubCategory: 'accounts/bySubCategory',
  createAccount: 'accounts/add',
  updateAccount: 'accounts/update',
  getAccounts: 'accounts',
  getAccountsDropDown: 'accounts/dropdown',
  getAccountsApprovals: 'accounts/approvals',
  approveAccount: 'accounts/approve',
  getChartOfAccount: 'accounts/coa',
  getChartOfAccountSubAccount: 'accounts/subAccounts',
  getAccountLedgers: 'accounts/ledger',
  getExportAccountLedgers: 'export/accounts/ledger',
  getGeneralJournalLedgers: 'accounts/generaljournal',
  getVaultCustomers: 'accounts/vault/customers',
  getVaultCustomersWithOutToken: 'accounts/vault/customersWithOutToken',
  getPaymentAccounts: 'accounts/vault/paymentAccounts',
  getPaymentAccountsWithOutToken: 'accounts/vault/paymentAccountsWithOutToken',
  getVaultTopUps: 'accounts/vault/topups',
  createVaultTopUp: 'accounts/vault/topup',
  getVaultTopUpDetail: 'accounts/vault/topups/details',
  getVaultTopUpPreview: 'accounts/vault/topups/preview',
  getAccountReports: 'accounts/reports',
  getCashierActivity: 'accounts/cashierActivity',
  PaymentVoucher: 'accounts/paymentVouchers/add',
  updatePaymentVoucher: 'accounts/paymentVouchers/add',
  reversePaymentVoucher: 'accounts/paymentVouchers/reverse',
  getVouchers: 'accounts/paymentVouchers',
  getVoucherDetails: 'accounts/paymentVouchers/details',
  deleteVoucher: 'accounts/paymentVouchers/delete',
  deleteReceipt: 'accounts/receiptVouchers/delete',
  deleteIFTV: 'accounts/iftVouchers/delete',
  ReceiptVoucher: 'accounts/receiptVouchers/add',
  getReceipts: 'accounts/receiptVouchers',
  getReceiptDetail: 'accounts/receiptVouchers/details',
  UpdateReceiptVoucher: 'accounts/receiptVouchers/add',
  reverseReceiptVoucher: 'accounts/receiptVouchers/reverse',
  createJournalVoucher: 'accounts/journalVouchers/add',
  getJournalVouchers: 'accounts/journalVouchers',
  deleteJournalVoucher: 'accounts/journalVouchers/delete',
  getJournalVoucherDetail: 'accounts/journalVouchers/details',
  ExportJournalVoucherPreview: 'accounts/journalVouchers/preview',
  createFundTransferVoucher: 'accounts/iftVouchers/add',
  getFundTransferVouchers: 'accounts/iftVouchers/',
  getFundTransferVoucherDetail: 'accounts/iftVouchers/details',
  getVehicleSumLedger: 'accounts/ledgerReports',
  getVehicleSumLedgerExport: 'export/accounts/ledgerReports',
  getShippingProfit: 'clients/shippingProfit',
  getShippingReceiving: 'accounts/shippingReceiving',
  getShippingAging: 'accounts/shippingReceivableAging',
  getFundTransferApproval: 'accounts/iftVouchers/approvals',
  FundTransferApprove: 'accounts/iftVouchers/approve',
  AccountBlance: 'accounts/balance',
  updateVaultTopUp: 'accounts/vault/topup',
  reverseVaultTopUp: 'accounts/vault/reverse',
  reverseJV: 'accounts/journalVouchers/reverse',
  ReverseIFTVoucher: 'accounts/iftVouchers/reverse',
  getReceiptPreview: 'accounts/receiptVouchers/preview',
  getVoucherPreview: 'accounts/paymentVouchers/preview',
  getJournalVoucherPreview: 'accounts/journalVouchers/preview',
  getFundTransferVoucherPreview: 'accounts/iftVouchers/preview',
  getPaymentAccountsShipping: 'accounts/vault/paymentAccounts/shipping',
};

export default routes
