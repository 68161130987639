import React, { Fragment, useState, useEffect, useMemo } from "react";
import {
  Grid,
  Box,
  Typography,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  tableCellClasses,
  Collapse,
  Paper,
  CircularProgress,
  IconButton,
  InputAdornment,
  Tooltip,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Dialog,
  TextField,
} from "@mui/material";
import { FontFamily, SearchIcon } from "assets";
import Colors from "assets/Style/Colors";
import { PrimaryButton } from "components/Buttons";
import SelectField from "components/Select";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { CommaSeparator } from "utils";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { makeStyles } from "@mui/styles";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Pagination from "components/Pagination";
import { ErrorToaster, SuccessToaster } from "components/Toaster";
import {
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from "@mui/icons-material";
import InputField from "components/Input";
import VendorServices from "services/Vendor";
import moment from "moment";
import SystemServices from "services/System";
import EditIcon from '@mui/icons-material/Edit';
import { CancelOutlined } from "@mui/icons-material";


// *For Table Style
const Row = styled(TableRow)(({ theme }) => ({
  border: 0,
}));

const Cell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 14,
    fontFamily: FontFamily.NunitoRegular,
    border: 0,
    textAlign: "center",
    whiteSpace: "nowrap",
    background: Colors.primary,
    color: Colors.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: FontFamily.NunitoRegular,
    textAlign: "center",
    textWrap: "nowrap",
    padding: "5px !important",

    ".MuiBox-root": {
      display: "flex",
      gap: "6px",
      alignItems: "center",
      justifyContent: "center",
      ".MuiBox-root": {
        cursor: "pointer",
      },
    },
    svg: {
      width: "auto",
      height: "24px",
    },
    ".MuiTypography-root": {
      textTransform: "capitalize",
      fontFamily: FontFamily.NunitoRegular,
      textWrap: "nowrap",
    },
    ".MuiButtonBase-root": {
      padding: "8px",
      width: "28px",
      height: "28px",
    },
  },
}));

const useStyles = makeStyles({
  loaderWrap: {
    display: "flex",
    height: 100,
    "& svg": {
      width: "40px !important",
      height: "40px !important",
    },
  },
});

function UpdateVendorPlan() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const { plan } = location?.state || {};
  const [isEditMode, setIsEditMode] = useState(!!plan);
  const [selectedRows, setSelectedRows] = useState(
    location.state?.selectedRows || []
  );
  const [loading, setLoading] = useState(false);
  const [shippingRates, setShippingRates] = useState([]);
  const [selectedShipping, setSelectedShipping] = useState("");
  const [towingRates, setTowingRates] = useState([]);
  const [selectedTowing, setSelectedTowing] = useState("");
  const [totalTowingCost, setTotalTowingCost] = useState(0);
  // *For Loading Port
  const [loadingPort, setLoadingPort] = useState([]);
  const [selectedLoadingPort, setSelectedLoadingPort] = useState(null);
  // *For Destination
  const [destinations, setDestinations] = useState([]);
  const [selectedDestination, setSelectedDestination] = useState(null);
  const [estimatePerUnit, setEstimatePerUnit] = useState(0);
  const [prevLocationIds, setPrevLocationIds] = useState([]);
  const [planId, setPlanId] = useState(null);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [tableId, setTableId] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [notes, setNotes] = useState({});


const handleEditClick = (row) => {
  setSelectedRow(row);
    setNotes((prevNotes) => ({
    ...prevNotes,
    [row.id]: prevNotes[row.id] || row.dispatch_notes || "",
  }));

  setOpenModal(true);
};

const handleNotesChange = (e) => {
  setNotes((prevNotes) => ({
    ...prevNotes,
    [selectedRow.id]: e.target.value,
  }));
};

const handleSaveNotes = () => {
  setOpenModal(false);
};

  
  // const locationIds = selectedRows?.map((row) => row?.location?.id)
  const locationIds = useMemo(
    () => selectedRows.map((row) => row?.location?.id).filter(Boolean),
    [selectedRows]
  );
  const tableHead = [
    "Customer",
    "VIN",
    "LOT",
    "Model",
    "TITLE",
    "Location Source",
    "Dispatch Status",
    "Notes"
  ];
  useEffect(() => {
    getDestinations();
    getLoadingPorts();
  }, []);
  useEffect(() => {
    if (
      locationIds.length > 0 &&
      JSON.stringify(locationIds) !== JSON.stringify(prevLocationIds)
    ) {
      getTowingRates();
      setPrevLocationIds(locationIds); // Save the last called state
    }
  }, [locationIds]);

  // *For Get Loading Port
  const getLoadingPorts = async () => {
    try {
      const { data } = await SystemServices.getLoadingPorts();
      setLoadingPort(data?.ports);
    } catch (error) {
      ErrorToaster(error);
    }
  };
  const getDestinations = async (search) => {
    try {
      let params = {
        page: 1,
        limit: 50,
        search: search,
      };
      const { data } = await SystemServices.getDestinations(params);
      setDestinations(data?.destinations?.rows);
    } catch (error) {
      ErrorToaster(error);
    }
  };
  // *For Vendor Costing
  const getShippingRates = async (portId, destId) => {
    setLoader(true);
    try {
      let params = {
        destination: destId,
        currency: "usd",
        vehicle_count: selectedRows?.length,
        loading_port: portId,
        // date: moment().format('2024-01-01')
      };
      const { data } = await VendorServices.getShippingRatesCheapset(params);
      setShippingRates(data?.rates);
    } catch (error) {
      setShippingRates([]);
      ErrorToaster(error);
    } finally {
      setLoader(false);
    }
  };
  const getTowingRates = async (page, limit, filter) => {
    setLoader(true);
    try {
      let locationIdString = locationIds?.length ? locationIds.join(",") : "";

      let params = {
        location_id: locationIdString,
        currency:
          selectedRows[0]?.location?.country_code == "US" ? "usd" : "cad",
      };
      const { data } = await VendorServices.getTowingRatesCheapset(params);
      let vendors = data?.vendors || [];
      if (vendors.length > 0) {
        setSelectedTowing({
          name: vendors[0]?.vendor?.name || "",
          id: vendors[0]?.vendor?.id || null,
        });
      }

      setTowingRates(vendors);
      // Calculate total towing cost
      let totalCost = vendors.reduce((acc, vendor) => {
        let vendorTotalCost = vendor?.locations?.reduce((sum, loc) => {
          return sum + (parseFloat(loc?.cost) || 0);
        }, 0);
        return acc + vendorTotalCost;
      }, 0);

      totalCost = totalCost.toFixed(2);

      // Update state values
      setTotalTowingCost(totalCost);
    } catch (error) {
      setTowingRates([]);
      ErrorToaster(error);
    } finally {
      setLoader(false);
    }
  };

  const handleCheckboxChange = (rowId) => {
    setSelectedRows((prevRows) =>
      prevRows.map((row) =>
        row.id === rowId 
          ? { 
            ...row, 
            dispatch_status: !row.dispatch_status, 
            dispatch_date: !row.dispatch_status ? new Date().toISOString() : null, 
           }
          : row
      )
    );
  };
  

  const updatePlan = async () => {
    try {
      setLoading(true);

      if (!selectedRows.length) {
        ErrorToaster("No vehicles selected for the plan.");
        return;
      }

      let vehicles = selectedRows.map((row) => ({
        customer_id: row?.customer?.id || null,
        source: row?.location?.city_name || "",
        source_id: row?.location_id,
        destination_id: selectedDestination?.id,
        loading_port_id: selectedLoadingPort?.id,
        destination: selectedDestination?.name || "",
        loading_port: selectedLoadingPort?.name || "",
        vin: row?.vin || "",
        lot_number: row?.lot_number || "",
        model: row?.veh_model?.id || "",
        title_status: row?.title_status || "",
        location_id: row?.location?.id,
        dispatch_notes: notes[row.id] || row?.dispatch_notes || null ,
        dispatch_status: row.dispatch_status|| null ,
        dispatch_date: row.dispatch_date || null,

      }));

      let planData = {
        plan_id: plan?.id, // Ensure this is set
        towing_cost: getValues("towingCost") || "0",
        shipping_cost: getValues("shippingCost") || "0",
        total_cost: getValues("totalPrice") || "0",
        booking_ref_no: getValues("bookingReferenceNo") || "",
        suggested_ship_vendor: selectedShipping?.name || "",
        suggested_ship_vendor_id: selectedShipping?.id || null,
        suggested_towing_vendor: selectedTowing?.name || "",
        suggested_towing_vendor_id: selectedTowing?.id || null,
        estimated_price: getValues("estimatedPrice") || "0",
        note: getValues("notes") || "",
        container_no: getValues("containerNo") || "",
        vehicles: vehicles,
        destination_id: selectedDestination?.id,
        loading_port_id: selectedLoadingPort?.id,
        customer_id: selectedRows[0]?.customer?.id || null,
      };

      await VendorServices.updatePlan(planData);
      SuccessToaster("Plan updated successfully");
      navigate("/create-plan");
    } catch (error) {
      ErrorToaster(error);
      setIsEditMode(true);
    } finally {
      setLoading(false);
    }
  };

  // Toggle state for the table body visibility
  const [open, setOpen] = useState(true);

  const handleToggle = () => {
    setOpen(!open);
  };
  const handleDeleteRow = (rowId) => {
    setSelectedRows((prevRows) => {
      const updatedRows = prevRows.filter((row) => row.id !== rowId);

      const vehicleCount = updatedRows.length || 1;

      const shippingCost = parseFloat(getValues("shippingCost")) || 0;
      const towingCost = parseFloat(getValues("towingCost")) || 0;

      const shippingPerUnit = parseFloat(
        (shippingCost / vehicleCount).toFixed(2)
      );
      setEstimatePerUnit(shippingPerUnit);

      setValue("estimatedPrice", (shippingPerUnit + towingCost).toFixed(2));

      setValue(
        "totalPrice",
        (shippingCost + towingCost * vehicleCount).toFixed(2)
      );

      return updatedRows;
    });
  };
  const handleChipClick = (vendor, type) => {
    const selectedCount = selectedRows?.length || 1;
    if (type === "shipping") {
      const newShippingCost = parseFloat(vendor?.cost) || 0;
      const currentTowingCost = parseFloat(getValues("towingCost")) || 0;

      // ✅ Calculate per-unit shipping cost & update estimate price
      const shippingPerUnit = parseFloat(
        (newShippingCost / selectedCount).toFixed(2)
      );
      setEstimatePerUnit(shippingPerUnit);
      setValue("shippingCost", newShippingCost);
      setValue(
        "estimatedPrice",
        (shippingPerUnit + currentTowingCost).toFixed(2)
      ); // Includes towing cost
      setValue(
        "totalPrice",
        (newShippingCost + currentTowingCost * selectedCount).toFixed(2)
      ); // Update total

      setSelectedShipping({ name: vendor?.name, id: vendor?.id });
    } else if (type === "towing") {
      const newTowingCost = parseFloat(vendor?.cost) || 0;
      const currentShippingCost = parseFloat(getValues("shippingCost")) || 0;

      // ✅ Replace previous towing cost instead of adding to it
      setValue("towingCost", newTowingCost);
      setValue("estimatedPrice", (newTowingCost + estimatePerUnit).toFixed(2)); // Ensure shipping per unit remains
      setValue(
        "totalPrice",
        (currentShippingCost + newTowingCost * selectedCount).toFixed(2)
      ); // Correctly updates total price

      setSelectedTowing({ name: vendor?.name, id: vendor?.id });
    }
  };

  useEffect(() => {
    if (location.state?.plan) {
      const { plan } = location.state;
      setValue("bookingReferenceNo", plan?.booking_ref_no || "");
      setValue("containerNo", plan?.container_no || "");
      setValue("notes", plan?.note || "");
      setValue("shippingCost", plan?.shipping_cost || "0");
      setValue("towingCost", plan?.towing_cost || "0");

      const firstVehicle = plan?.vehicles?.[0] || {};

      const loadingPortData =
        firstVehicle?.loading_port_details ?? {
          id: firstVehicle?.loading_port_id,
          name: firstVehicle?.loading_port,
        } ??
        null;

      const destinationData =
        firstVehicle?.dest ?? {
          id: firstVehicle?.destination_id,
          name: firstVehicle?.destination,
        } ??
        null;

      setSelectedLoadingPort(loadingPortData);
      setSelectedDestination(destinationData);

      // ✅ Set values explicitly in React Hook Form
      setValue("loadingPort", loadingPortData?.id || "");
      setValue("destination", destinationData?.id || "");

      setPlanId(plan?.id);
      setSelectedShipping(
        {
          name: plan?.suggested_ship_vendor,
          id: plan?.suggested_ship_vendor_id,
        } || ""
      );
      setSelectedTowing(
        {
          name: plan?.suggested_towing_vendor,
          id: plan?.suggested_towing_vendor_id,
        } || ""
      );
      setSelectedRows(plan?.vehicles || []);

      // ✅ Calculate total price based on shipping & towing costs
      const vehicleCount = plan?.vehicles?.length || 1;
      const shippingCost = parseFloat(plan?.shipping_cost) || 0;
      const towingCost = parseFloat(plan?.towing_cost) || 0;
      const towingCostTotal = parseFloat(plan?.towing_cost * vehicleCount) || 0;

      // ✅ Per unit estimate
      const shippingPerUnit = parseFloat(
        (shippingCost / vehicleCount).toFixed(2)
      );
      setEstimatePerUnit(shippingPerUnit);

      // ✅ Set estimated & total price
      setValue("estimatedPrice", plan?.estimated_price || 0);
      setValue("totalPrice", (shippingCost + towingCostTotal).toFixed(2));
    }
  }, [plan, isEditMode]);

  // useEffect(() => {
  //   if (selectedRows.length > 0) {
  //       const vehicleCount = selectedRows.length || 1;

  //       const shippingCost = parseFloat(getValues("shippingCost")) || 0;
  //       const towingCost = parseFloat(getValues("towingCost")) || 0;

  //       // ✅ Recalculate shipping cost per unit
  //       const shippingPerUnit = parseFloat((shippingCost / vehicleCount).toFixed(2));
  //       setEstimatePerUnit(shippingPerUnit);

  //       // ✅ Update estimated and total prices
  //       // setValue("estimatedPrice", parseFloat(shippingPerUnit + towingCost).toFixed(2));
  //       setValue("totalPrice", (shippingCost + (towingCost * vehicleCount)).toFixed(2));
  //   }
  // }, [selectedRows, setValue]);


  return (
    <Box
      component={"form"}
      onSubmit={handleSubmit(updatePlan)}
      sx={{ m: 4, mb: 8 }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "11px",
          alignItems: "center",
          mb: 1,
          mt: 6,
        }}
      >
        <IconButton onClick={() => navigate("/create-plan")} disableRipple>
          <ArrowBackIcon />
        </IconButton>
        <Typography
          variant="h5"
          sx={{
            color: Colors.charcoalGrey,
            fontFamily: FontFamily.NunitoRegular,
          }}
        >
          Edit Assign Vehicles
        </Typography>
      </Box>

       <Grid
              container
              spacing={1}
              alignItems={"center"}
              justifyContent={"center"}
            >

<Dialog
   open={openModal} onClose={() => setOpenModal(false)}
              sx={{
                  "& .MuiDialog-paper": {
                      width: "30%",
                      height: "auto",
                      borderRadius: 2,
                      py: { xs: 2, md: 4 },
                      px: { xs: 3, md: 6 },
                  },
              }}
          >
              <IconButton onClick={() => setOpenModal(false)} sx={{ position: "absolute", right: 13, top: 13 }}>
                  <CancelOutlined />
              </IconButton>
              <Box component={"form"}>
              <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: "bold" }}>
      Dispatch Notes
    </Typography>
              <TextField
        fullWidth
        multiline
        rows={1}
        value={notes[selectedRow?.id] ?? ""}
        onChange={handleNotesChange}
        placeholder="Enter dispatch notes"
      />
    
                  <Box sx={{ width: "100%", display: "flex", justifyContent: "center", mt: 2 }}>
                  <PrimaryButton title="Submit" onClick={handleSaveNotes} />
                  </Box>
              </Box>
          </Dialog>


      </Grid>

      {/* ========== Table Listing ========== */}

      {/* <Box
        sx={{
          border: "1px solid #00000099",
          borderRadius: "4px",
          overflow: "hidden",
        }}
      > */}
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "0px 8px 18px 0px #9B9B9B1A",
          borderRadius: 2,
          maxHeight: "calc(100vh - 330px)",
          mt: 2,
        }}
        className="table-box"
      >
        <Table stickyHeader sx={{ minWidth: 500 }}>
          {/* Table Header */}
          <TableHead>
            <TableRow>
              {tableHead.map((header, index) => (
                <Cell key={index} sx={{}}>
                  {header}
                </Cell>
              ))}
              <Cell sx={{}}>
                <IconButton
                  onClick={handleToggle}
                  sx={{
                    width: "40px",
                    height: "36px",
                    borderRadius: "4px",
                    backgroundColor: "#FFFFFF",
                    "&:hover": {
                      backgroundColor: "#FFFFFF",
                    },
                  }}
                >
                  {open ? (
                    <ExpandLessIcon sx={{ color: "#0C6135" }} />
                  ) : (
                    <ExpandMoreIcon sx={{ color: "#0C6135" }} />
                  )}
                </IconButton>
              </Cell>
            </TableRow>
          </TableHead>

          {open && (
            <TableBody>
              {selectedRows?.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  <Cell>{row?.customer?.name ?? "-"}</Cell>
                  <Cell>{row?.vin ?? "-"}</Cell>
                  <Cell>{row?.lot_number ?? "-"}</Cell>
                  <Cell>{row?.veh_model?.name ?? "-"}</Cell>
                  <Cell>{row?.title_status ?? "-"}</Cell>
                  <Cell>{row?.location?.city_name ?? "-"}</Cell>
                  <Cell><Checkbox 
                  checked={row.dispatch_status || false}
                  onChange={() => handleCheckboxChange(row.id)}/></Cell>
                  <Cell>
                    <Box
                     component={"div"}
                     onClick={() => handleEditClick(row)}
                    >
                    <EditIcon />
                    </Box>
                  </Cell>
                  <Cell>
                    <IconButton
                      onClick={() => handleDeleteRow(row.id)}
                      sx={{
                        width: "36px",
                        height: "36px",
                        borderRadius: "4px",
                        border: "1px solid #0000004D",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <DeleteIcon sx={{ color: "#F24822" }} />
                    </IconButton>
                  </Cell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {/* </Box> */}
      <Box
        sx={{
          p: 3,
          marginTop: 4,
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={3}>
            <SelectField
              label={"Loading Port"}
              options={loadingPort}
              selected={selectedLoadingPort}
              onSelect={(value) => {
                setSelectedLoadingPort(value);
                if (value && selectedDestination) {
                  getShippingRates(value?.id, selectedDestination?.id);
                }
              }}
              error={errors?.loadingPort?.message}
              register={register("loadingPort", {
                required: "Please select loading port.",
              })}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <SelectField
              onSearch={(v) => getDestinations(v)}
              label={"Destination"}
              options={destinations}
              selected={selectedDestination}
              onSelect={(value) => {
                setSelectedDestination(value);
                if (selectedLoadingPort && value) {
                  getShippingRates(selectedLoadingPort?.id, value?.id);
                } else {
                  // ErrorToaster('Please select both Loading Port and Destination.');
                }
              }}
              error={errors?.destination?.message}
              register={register("destination", {
                required: "Please select destination.",
              })}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <InputField
              size={"small"}
              label={"Dispatch Reference no."}
              placeholder={"Dispatch Reference no."}
              register={register("bookingReferenceNo", {
                required: "Booking Reference number is required",
              })}
              error={errors?.bookingReferenceNo?.message}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputField
              placeholder={"Container no."}
              label={"Container no."}
              register={register("containerNo")}
              optional
            />
          </Grid>
          <Grid item xs={6} mt={3}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  flexWrap: "wrap",
                  marginTop: -3,
                }}
              >
                {shippingRates
                  .slice()
                  .sort((a, b) => a.cost - b.cost)
                  .map((vendor, ind) => (
                    <Tooltip
                      key={vendor.id}
                      title={`${vendor?.vendor?.name} - ${vendor?.lines?.name} - ${vendor?.cost}`}
                      arrow
                      placement="top"
                    >
                      <Chip
                        label={`${vendor?.vendor?.name} - ${vendor?.lines?.name} - ${vendor?.cost}`}
                        sx={{
                          backgroundColor:
                            ind === 0
                              ? "#def2cf"
                              : ind === 1
                              ? "#e6e8f3"
                              : "#f8e7eb",
                          color:
                            ind === 0
                              ? "#5abc0e"
                              : ind === 1
                              ? "#031883"
                              : "#a2001d",
                          fontWeight: "bold",
                          cursor: "pointer",
                          fontSize: "12px",
                          width: "170px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                        onClick={() =>
                          handleChipClick(
                            {
                              name: `${vendor?.vendor?.name} - ${vendor?.lines?.name}`,
                              cost: vendor?.cost,
                              id: vendor?.vendor?.id,
                            },
                            "shipping"
                          )
                        }
                      />
                    </Tooltip>
                  ))}
              </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
              {/* <SelectField
                label="Suggested Shipping Vendors"
                options={shippingRates.map((item) => ({
                  id: item.id,
                  name: `${item?.vendor?.name} - ${item?.lines?.name}`,
                  cost: item.cost,
                }))}
                selected={selectedShipping}
                onSelect={(value) => {
                  if (value?.id) {
                    handleChipClick(value, "shipping");
                  }
                }}
                register={register("shippingVendors", {
                  // required: "Shipping Vendors are required",
                })} 
              />*/}
            </Grid>
          </Grid>
          {/* Suggested Towing Vendors */}
          <Grid item xs={6} mt={3}>
            <Grid item xs={12}>
             {/* <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  flexWrap: "wrap",
                  marginTop: -4,
                }}
              >
                 {towingRates
                  .slice()
                  .sort((a, b) => a.total_cost - b.total_cost)
                  .map((vendor, ind) => (
                    <Tooltip
                      key={vendor.id}
                      title={`${vendor?.vendor?.name} - ${vendor?.total_cost}`}
                      arrow
                      placement="top"
                    >
                      <Chip
                        label={`${vendor?.vendor?.name} - ${vendor?.total_cost}`}
                        sx={{
                          backgroundColor:
                            ind === 0
                              ? "#def2cf"
                              : ind === 1
                              ? "#e6e8f3"
                              : "#f8e7eb",
                          color:
                            ind === 0
                              ? "#5abc0e"
                              : ind === 1
                              ? "#031883"
                              : "#a2001d",
                          fontWeight: "bold",
                          cursor: "pointer",
                          fontSize: "12px",
                          width: "170px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                        onClick={() =>
                          handleChipClick(
                            {
                              name: vendor?.vendor?.name,
                              cost: vendor?.total_cost,
                              id: vendor?.vendor?.id,
                            },
                            "towing"
                          )
                        }
                      />
                    </Tooltip>
                  ))} 
              </Box>*/}
            </Grid>
            <Grid item xs={12} sm={12} mt={1}>
              {/* <SelectField
                label="Suggested Towing Vendors"
                options={towingRates.map((item) => ({
                  id: item.vendor?.id,
                  name: item?.vendor?.name,
                  cost: item?.total_cost,
                }))}
                selected={selectedTowing}
                onSelect={(value) => {
                  if (value?.id) {
                    handleChipClick(value, "towing");
                  }
                }}
                register={register("towingVendors")}
              /> */}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3}>
            {/* <InputField
              disabled={true}
              placeholder={"Estimated Price"}
              label={"Est. Price Per Unit"}
              register={register("estimatedPrice", {
                // required: "Estimated Price is required",
                pattern: {
                  value: /^[0-9]+(\.[0-9]{1,2})?$/,
                  message: "Please enter a valid price",
                },
              })}
            /> */}
          </Grid>
          <Grid item xs={12} sm={3}>
            {/* <InputField
              disabled={true}
              placeholder={"Total Price"}
              label={"Total Price"}
              defaultValue={parseFloat(
                selectedShipping?.cost + selectedTowing?.cost || 0
              )}
              register={register("totalPrice", {
                // required: "Estimated Price is required",
                pattern: {
                  value: /^[0-9]+(\.[0-9]{1,2})?$/,
                  message: "Please enter a valid price",
                },
              })}
            /> */}
          </Grid>

          <Grid item xs={12} sm={6}>
            <InputField
              placeholder={"Notes"}
              label={"Notes"}
              register={register("notes", {
                maxLength: {
                  value: 500,
                  message: "Notes cannot exceed 500 characters",
                },
              })}
            />
          </Grid>
        </Grid>
      </Box>
      <Grid item xs={12} sm={3} mt={2} textAlign={"right"}>
        <PrimaryButton
          loading={loading}
          type="submit"
          title={"Update"}
        />
      </Grid>
    </Box>
  );
}

export default UpdateVendorPlan;
