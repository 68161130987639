import React, { Fragment, useEffect, useState } from "react";
import {
    Grid,
    Box,
    Typography,
    Paper,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Radio,
    RadioGroup,
    FormControlLabel,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    tableCellClasses,
    CircularProgress,
    Checkbox,
    Collapse,
    IconButton,
    ListItemText

} from "@mui/material";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";

import { PrimaryButton } from "components/Buttons";
import InputField from "components/Input";
import SelectField from "components/Select";
import DatePicker from "components/DatePicker";
import { useForm } from "react-hook-form";
import { CreditCard, CreditScore } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ClosingPeriodServices from "services/ClosingPeriod";
import { ErrorToaster, SuccessToaster } from "components/Toaster";
import styled from "@emotion/styled";
import { FontFamily, RequestBuyerIdIcon, SearchIcon } from "assets";
import Colors from "assets/Style/Colors";
import { makeStyles } from "@mui/styles";
import { CommaSeparator } from "utils";

const Row = styled(TableRow)(({ theme }) => ({
    border: 0,
}));

const Cell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 14,
        fontFamily: FontFamily.NunitoRegular,
        border: 0,
        padding: "15px",
        textAlign: "center",
        whiteSpace: "nowrap",
        backgroundColor: Colors.primary,
        color: Colors.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontFamily: FontFamily.NunitoRegular,
        textAlign: "center",
        textWrap: "nowrap",

        padding: '5px !important',
        ".MuiBox-root": {
            display: "flex",
            gap: "6px",
            alignItems: "center",
            justifyContent: "center",
            ".MuiBox-root": {
                cursor: "pointer",
            },
        },
        svg: {
            width: "auto",
            height: "24px",
        },
        ".MuiTypography-root": {
            textTransform: "capitalize",
            fontFamily: FontFamily.NunitoRegular,
            textWrap: "nowrap",
        },
        ".MuiButtonBase-root": {
            padding: "8px",
            width: "28px",
            height: "28px",
        },
    },
}));

const useStyles = makeStyles({
    loaderWrap: {
        display: "flex",
        height: 100,
        "& svg": {
            width: "40px !important",
            height: "40px !important",
        },
    },
});
const ClosingPeriods = () => {
    const classes = useStyles();

    const { register, handleSubmit, setValue } = useForm();
    const [closingModules, setClosingModules] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState(new Date());
    const [selectedPrevBalance, setSelectedPrevBalance] = useState("Dec 2024");
    const [purchaseDate, setPurchaseDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [selectedAccounts, setSelectedAccounts] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [periodOptions, setPeriodOptions] = useState([]);
    const [saveOption, setSaveOption] = useState("draft");
    const [totalDebit, setTotalDebit] = useState(0)
    const [totalCredit, setTotalCredit] = useState(0)
    const [openRows, setOpenRows] = useState({});
    const [openModules, setOpenModules] = useState({});
    const [selectedModules, setSelectedModules] = useState([])
    const navigate = useNavigate();
    // const tableHead = ["Role ID", "Name", "Actions"];
    const tableHead = ["Select", "Account Code", "Name",];


    useEffect(() => {
        getAccounts()
        getPeriods()
    }, [])

    const getPeriods = async () => {
        try {
            const res = await ClosingPeriodServices.getPeriods();

            if (res?.data) {
                // Convert object to array
                const periodArray = res.data?.closingPeriods;

                // Format periods for dropdown
                const formattedPeriods = periodArray.map((period) => ({
                    id: period.id,
                    name: new Date(period.start_date).toLocaleString('en-US', { month: 'long', year: 'numeric' }),
                    status: period.status,
                    total_debit: period.total_debit, // ✅ Store debit
                    total_credit: period.total_credit, // ✅ Store credit
                }));

                setPeriodOptions(formattedPeriods);
            } else {
                setPeriodOptions([]);
            }
        } catch (error) {
            ErrorToaster(error);
        }
    };


    const getAccounts = async () => {
        try {
            const res = await ClosingPeriodServices.getAccounts();
            if (res?.data) {
                setAccounts(res?.data?.modules);
            } else {
                setAccounts([]);
            }
        } catch (error) {
            ErrorToaster(error);
        } finally {
            setLoading(false);
        }
    };

    const createClosePeriod = async () => {
        try {
            setLoading(true);

            if (!selectedModules.length) {
                ErrorToaster("Please select at least one account.");
                return;
            }

            let obj = {
                end_date: purchaseDate,
                status: saveOption,
                notes: "",
                accounts: selectedModules,
            };


            const res = await ClosingPeriodServices.createPeriod(obj);

            if (res?.status) {
                SuccessToaster(res?.message);
                setSelectedAccounts([])
                navigate("/closing-period-list");
            } else {
                ErrorToaster("Failed to create closing period.");
            }
        } catch (error) {
            ErrorToaster(error);
        } finally {
            setLoading(false);
        }
    };

 
    const handleSaveOptionChange = (event) => {
        setSaveOption(event.target.value);
    };
    // const handlePeriodChange = (value) => {
    //     const selectedId = value;
    //     setSelectedPeriod(selectedId);

    //     // ✅ Find the selected period data
    //     const selectedData = periodOptions.find((period) => period.id === selectedId);
    //     if (selectedData) {
    //         setTotalDebit(parseFloat(selectedData.total_debit || 0).toFixed(2));
    //         setTotalCredit(parseFloat(selectedData.total_credit || 0).toFixed(2));
    //     }
    // }; 
    const handlePeriodChange = (value) => {
        if (!value) return;

        setSelectedPeriod(value);

        // ✅ Find the selected period data
        const selectedData = periodOptions.find((period) => period.id === value);
        if (selectedData) {
            setTotalDebit(parseFloat(selectedData.total_debit || 0).toFixed(2));
            setTotalCredit(parseFloat(selectedData.total_credit || 0).toFixed(2));
        }
    };
    // Toggle row collapse
   
    // Handle selecting/deselecting individual accounts
    // Handle selecting/deselecting individual accounts
    //   const handleSelectAccount = (accountId) => {
    //     setSelectedAccounts((prev) => ({
    //       ...prev,
    //       [accountId]: !prev[accountId], // Toggle selection
    //     }));
    //   };

    // Handle selecting all accounts in a category
    const handleSelectAlll = (categoryId, accounts) => {
        setSelectedAccounts((prev) => {
            if (!Array.isArray(prev)) prev = []; // Ensure prev is an array

            const allSelected = accounts.every((account) => prev.includes(account.id));

            return allSelected
                ? prev.filter((id) => !accounts.some((account) => account.id === id)) // Remove all IDs in this category
                : [...prev, ...accounts.map((account) => account.id).filter((id) => !prev.includes(id))]; // Add only new IDs
        });
    };
    const handleToggleModule = (moduleName) => {
        setOpenModules({ [moduleName]: !openModules[moduleName] });
    };

    const handleSelectModule = (moduleName, childs = []) => {
        if (!Array.isArray(childs)) return;
        const allSelected = childs.every((child) => Array.isArray(selectedModules) && child.accounts.every(account => selectedModules.includes(account)));
        setSelectedModules((prevSelected) => {
            if (allSelected) {
                return prevSelected.filter((item) => !childs.some((child) => child.accounts.includes(item)));
            } else {
                return [...prevSelected, ...childs.flatMap((child) => child.accounts).filter((account) => !prevSelected.includes(account))];
            }
        });
    };

    const handleSelectChild = (childAccounts) => {
        setSelectedModules((prevSelected) => {
            if (!Array.isArray(prevSelected)) prevSelected = [];
            const allSelected = childAccounts.every(account => prevSelected.includes(account));
            return allSelected
                ? prevSelected.filter((item) => !childAccounts.includes(item))
                : [...prevSelected, ...childAccounts.filter(account => !prevSelected.includes(account))];
        });
    };

    const handleSelectAll = () => {
        const allModuleAccounts = accounts.flatMap(module => module.childs?.flatMap(child => child.accounts) || []);
        const allSelected = allModuleAccounts.every(account => selectedModules.includes(account));
        setSelectedModules(allSelected ? [] : allModuleAccounts);
    };

    return (
        <Box sx={{ m: 4, mb: 8 }}>
            <Typography variant="h4" sx={{ mb: 2, color: "#094526" }}>
                Closing Periods
            </Typography>

            <Paper sx={{ p: 3, boxShadow: "0px 8px 18px #9B9B9B1A", borderRadius: 2 }}>
                <Grid container
                // spacing={2} alignItems="center"
                >
                    <Grid item xs={6} sm={6} >
                        <Grid container spacing={2} display={"flex"} alignItems={"center"}>
                            {/* Date Picker - As of Date */}
                            <Grid item xs={6} md={6}>
                                <DatePicker
                                    size={"small"}
                                    label={"As of Date"}
                                    value={purchaseDate}
                                    register={register("purchaseDate", { required: "Date is required" })}
                                    onChange={(date) => setPurchaseDate(date)}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <FormControl fullWidth>
                                    <Select
                                        displayEmpty
                                        multiple
                                        value={[]}
                                        renderValue={(selected) => selected?.length ? selected?.join(", ") : "Select options"}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 500,
                                                    overflow: 'auto'
                                                }
                                            }
                                        }}
                                        sx={{
                                            height: "45px",
                                            borderRadius: 3,
                                            "& .MuiOutlinedInput-root": {
                                                borderRadius: 3,
                                            },
                                            marginTop:3
                                        }}
                                    >
                                        <MenuItem>
                                            <Checkbox
                                                checked={accounts?.flatMap(module => module.childs?.flatMap(child => child.accounts) || []).every(account => selectedModules.includes(account))}
                                                onChange={handleSelectAll}
                                            />
                                            <ListItemText primary="Select All" />
                                        </MenuItem>
                                        {accounts?.map((module) => (
                                            <div key={module.name}>
                                                <MenuItem>
                                                    <Checkbox
                                                        checked={module.childs?.every((child) => Array.isArray(selectedModules) && child.accounts.every(account => selectedModules.includes(account)))}
                                                        onChange={() => handleSelectModule(module.name, module.childs || [])}
                                                    />
                                                    <ListItemText primary={module.name} />
                                                    <IconButton size="small" onClick={() => handleToggleModule(module.name)}>
                                                        {openModules[module.name] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                                    </IconButton>
                                                </MenuItem>
                                                <Collapse in={openModules[module.name]} timeout="auto" unmountOnExit>
                                                    <Box pl={4}>
                                                        {(module.childs || []).map((child) => (
                                                            <MenuItem key={child.accounts[0]}>
                                                                <Checkbox
                                                                    checked={Array.isArray(selectedModules) && child.accounts.every(account => selectedModules.includes(account))}
                                                                    onChange={() => handleSelectChild(child.accounts)}
                                                                />
                                                                <ListItemText primary={child.name} />
                                                            </MenuItem>
                                                        ))}
                                                    </Box>
                                                </Collapse>
                                            </div>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* Save as Draft / Temporary Save */}
                            <Grid item xs={12} sm={7} mt={2}>
                                <RadioGroup
                                    row
                                    value={saveOption}
                                    onChange={handleSaveOptionChange}
                                >
                                    <FormControlLabel
                                        value="draft"
                                        control={<Radio />}
                                        label="Save as Draft"
                                    />
                                    <FormControlLabel
                                        value="temporary"
                                        control={<Radio />}
                                        label="Temporary Save"
                                    />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Vertical Divider */}
                    <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />

                    {/* Previous Closing Balance Section */}
                    <Grid item xs={12} sm={3}>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Prev. Closing Balance
                        </Typography>

                        {/* All Periods Dropdown */}
                        <Grid item xs={12} md={12}>
                            {/* Title Above Input Field */}
                            <Typography variant="body2" sx={{ fontWeight: 600, mb: 1 }}>
                                All Periods
                            </Typography>

                            <FormControl fullWidth>
                                <Select
                                    displayEmpty
                                    value={selectedPeriod || ""}
                                    onChange={(e) => handlePeriodChange(e.target.value)}
                                    renderValue={(selected) => {
                                        if (!selected) {
                                            return <span style={{ color: "#999" }}>Select a period</span>; // ✅ Placeholder inside input field
                                        }
                                        const selectedData = periodOptions.find((period) => period.id === selected);
                                        return selectedData ? selectedData.name : "";
                                    }}
                                    sx={{
                                        height: "45px",
                                        borderRadius: 3,
                                        "& .MuiOutlinedInput-root": {
                                            borderRadius: 3,
                                        }
                                    }}
                                >
                                    {/* Do not add placeholder in dropdown */}
                                    {periodOptions.length === 0 ? (
                                        <MenuItem disabled>
                                            <em>No periods available</em>
                                        </MenuItem>
                                    ) : (
                                        periodOptions.map((option) => (
                                            <MenuItem
                                                key={option.id}
                                                value={option.id}
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    gap: "12px" // Increased spacing between date and status
                                                }}
                                            >
                                                <span>{option.name}</span>
                                                {option.status && (
                                                    <span
                                                        style={{
                                                            backgroundColor: option.status === "active" ? "#def2cf"
                                                                : option.status === "closed" ? "#f8e7eb"
                                                                    : "#e6e8f3",
                                                            color: option.status === "active" ? "#5abc0e"
                                                                : option.status === "closed" ? "#a2001d"
                                                                    : "#031883",
                                                            padding: "2px 10px", // Better spacing
                                                            borderRadius: "8px",
                                                            fontSize: "12px",
                                                            fontWeight: "bold",
                                                            minWidth: "60px", // Consistent size
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        {option.status}
                                                    </span>
                                                )}
                                            </MenuItem>
                                        ))
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>



                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                mt: 2,
                                p: 2,
                                // border: "1px solid #ddd",
                                borderRadius: 2,
                                // bgcolor: "#F8F8F8",
                                gap: 8
                            }}
                        >
                            {/* Total Debit */}
                            <Box sx={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", gap: 2 }}>
                                <Box sx={{ bgcolor: "#07E2F2", borderRadius: 100, height: 42, width: 42, justifyContent: "center", alignItems: "center", display: "flex" }}>
                                    <CreditCard sx={{ fontSize: "20px" }} />
                                </Box>
                                <Box>
                                    <Typography sx={{ fontWeight: "bold", fontSize: "14px", mt: 0.5 }}>
                                        Total Debit
                                    </Typography>
                                    <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                                        {CommaSeparator(totalDebit)}
                                    </Typography>
                                </Box>
                            </Box>

                            {/* Total Credit */}
                            <Box sx={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", gap: 2 }}>
                                <Box sx={{ bgcolor: "#EA8CFD", borderRadius: 100, height: 42, width: 42, justifyContent: "center", alignItems: "center", display: "flex" }}>
                                    <CreditScore sx={{ fontSize: "20px" }} />
                                </Box>
                                <Box>
                                    <Typography sx={{ fontWeight: "bold", fontSize: "14px", mt: 0.5 }}>
                                        Total Credit
                                    </Typography>
                                    <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                                        {CommaSeparator(totalCredit)}
                                    </Typography>
                                </Box>
                            </Box>

                        </Box>


                        {/* Unbalanced Alert */}
                        <Box
                            sx={{
                                bgcolor: "#F8F8F8",
                                borderRadius: 2,
                                p: 1,
                                mt: 2,
                                textAlign: "center",
                                border: "0px solid #ccc"
                            }}
                        >
                            <Typography sx={{ fontSize: "12px", color: "black", fontWeight: "bold" }}>
                                {parseFloat(totalDebit - totalCredit).toFixed(2)}
                            </Typography>

                            {parseFloat(totalDebit - totalCredit).toFixed(2) === "0.00" ? (
                                <Typography sx={{ fontSize: "14px", fontWeight: "bold", color: "green" }}>
                                    ✅ Balanced
                                </Typography>
                            ) : (
                                <Typography sx={{ fontSize: "14px", fontWeight: "bold", color: "red" }}>
                                    ❗ Unbalanced
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                </Grid>

                {/* Save Button */}
                <Box sx={{ mt: 3, textAlign: "right" }}>
                    <PrimaryButton loading={loading} onClick={() => { createClosePeriod() }} title="Save" />
                </Box>
            </Paper>
        </Box>
    );
};

export default ClosingPeriods;
