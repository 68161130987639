import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  TablePagination,
} from '@mui/material';
import SystemServices from 'services/System';

const ScheduleReportsDashboard = () => {
  const [scheduleReports, setScheduleReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchScheduleReports = async () => {
      try {
        const response = await SystemServices.getAllScheduleReports();
        setScheduleReports(response.data.scheduleReports);
      } catch (error) {
        console.error('Error fetching schedule reports:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchScheduleReports();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer component={Paper} sx={{ m: 3 }}>
      <Typography variant='h5' sx={{ p: 2 }}>
        Schedule Reports
      </Typography>
      {loading ? (
        <CircularProgress sx={{ display: 'block', margin: '20px auto' }} />
      ) : (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                  Subject
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                  Description
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                  Schedule Type
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                  Schedule Date
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                  Last Run
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                  Status
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                  Last Sent Count
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {scheduleReports
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((report) => (
                  <TableRow key={report.id}>
                    <TableCell>{report.subject}</TableCell>
                    <TableCell>{report.description}</TableCell>
                    <TableCell>{report.schedule_type}</TableCell>
                    <TableCell>
                      {new Date(report.schedule_date).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      {new Date(report.last_run).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      {report.is_active ? 'Active' : 'Inactive'}
                    </TableCell>
                    <TableCell>{report.sent_count}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={scheduleReports.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </TableContainer>
  );
};

export default ScheduleReportsDashboard;
