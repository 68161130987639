import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { ExpandLess, ExpandMore, PowerSettingsNew } from "@mui/icons-material";
import Navigation from "./Navigation";
import Colors from "assets/Style/Colors";
import { FontFamily, IdCardIcon, Images, SvgIcon } from "assets";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { PrimaryButton } from "components/Buttons";
import SimpleDialog from "components/Dialog/SimpleDialog";
import ClientServices from "services/Client";
import { ErrorToaster } from "components/Toaster";
import SelectField from "components/Select";
import { useNavigate } from "react-router-dom";
import { useAuth } from "context/UseContext";
import InputField from "components/Input";
import SystemServices from "services/System";
import MenuIcon from "@mui/icons-material/Menu";
import AuthServices from "services/Auth";
import WestIcon from "@mui/icons-material/West";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import ConfirmationDialog from "components/Dialog/ConfirmationDialog";
import Avatar from "@mui/material/Avatar";
import { Badge } from "@mui/base";
import LogoutIcon from "@mui/icons-material/Logout";
import { setEmployeeAuthentication } from "redux/slices/navigationDataSlice";
import { useDispatch } from "react-redux";
function Sidebar({ onClick }) {
  const navigate = useNavigate();
  const { user, userLogout } = useAuth();
  const { pathname } = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm();

  const { navigation } = useSelector((state) => state.navigationReducer);

  const [expand, setExpand] = useState([]);

  // *For Vin Dialog
  const [vinDialog, setVinDialog] = useState(false);
  // *For Vin and Lot
  const [vin, setVin] = useState([]);
  const [selectedVin, setSelectedVin] = useState(null);
  const [lot, setLot] = useState([]);
  const [selectedLot, setSelectedLot] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();

  // *For Dialog Box
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async (formData) => {
    let obj = {
      fcm_token: localStorage.getItem("fcmToken"),
    };
    try {
      const { message } = await AuthServices.handleLogout(obj);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const myNavigation = [
    // {
    //   name: "dashboard",
    //   icon: "home",
    //   route: "/dashboard",
    //   userType: "",
    //   childRoute: [""],
    //   children: [
    // {
    //   name: "Galaxy Operations",
    //   route: "/galaxy-operations",
    // },
    // {
    //   name: "Finance",
    //   route: "/finance",
    // },
    // {
    //   name: "Global Search",
    //   route: "/global-search",
    // },
    // {
    //   name: "Operations",
    //   route: "/operations",
    // },

    // {
    //   name: "Search Vin/Lot",
    //   route: "",
    // },
    // {
    //   name: "Galaxy CustomerWise VCC Reports",
    //   route: "/customerwise-vcc-reports",
    // },
    // {
    //   name: "Buyer Id",
    //   route: "/buyer-id-dashboard",
    // },
    // {
    //   name: "Wallet",
    //   route: "/wallet-dashboard",
    // },
    // {
    //   name: "Management Reports",
    //   route: "/management-reports",
    // },
    // {
    //   name: "Export Dashboard",
    //   route: "/export-dashboard",
    // },
    // {
    //   name: "Customer Service",
    //   route: "/customer-service",
    // },
    // ],
    // },
  ];

  const subCustomer = [
    {
      name: "My Customers",
      route: "/my-customers",
      icon: "customer",
      userType: "",
      childRoute: [""],
      children: [],
    },
    {
      name: "My Vehicles",
      route: "/my-vehicles",
      icon: "vehicle",
      userType: "",
      childRoute: [""],
      children: [],
    },
  ];

  const handleClick = () => {
    setVinDialog(true);
  };

  // *For Vin
  const getVin = async (search) => {
    try {
      let params = {
        page: 1,
        limit: 50,
        search: search,
      };
      const { data } = await ClientServices.getTTVin(params);

      let arrayOfObjects = data?.details?.vins.map((item) => {
        return { id: item.vin, name: item.vin, ...item };
      });

      let arrayOfObjects1 = data?.details?.lots.map((item) => {
        return { id: item.lot, name: item.lot, ...item };
      });

      setVin(arrayOfObjects);
      setLot(arrayOfObjects1);

      setVin([...arrayOfObjects, ...arrayOfObjects1]);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  // *For Apply Filters
  const applyFilter = async (data) => {
    try {
      let data = {
        search: getValues("search"),
      };
      getVehicleTT(1, "", data);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  // *For TTs
  const getVehicleTT = async (page, search, filter) => {
    const Filter = { ...filter };

    try {
      let params = {
        page: 1,
        limit: 15,
      };
      params = { ...params, ...Filter };
      const { data } = await ClientServices.getVehicleTT(params);
      if (data?.details) {
        navigate(`/vehicle-booking-detail/${data?.details?.id}`);
        setVinDialog(false);
      } else {
        ErrorToaster("No Vehicle Found");
      }
    } catch (error) {
      ErrorToaster(error);
    }
  };

  // *For getWebTokens
  const getWebTokens = async () => {
    try {
      const { data } = await SystemServices.getWebTokens();
      localStorage.setItem("financeToken", data?.financeToken);
      localStorage.setItem("vccToken", data?.vccToken);
      localStorage.setItem("operationsToken", data?.operationsToken);
      localStorage.setItem("searchToken", data?.searchToken);
      localStorage.setItem("walletToken", data?.walletToken);
      localStorage.setItem("buyerIdToken", data?.buyerIdToken);
      localStorage.setItem("managementReports", data?.managementReports);
      localStorage.setItem("exportReports", data?.exportReports);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const handleCollapse = (value) => {
    const isExpanded = expand.includes(value);
    let newExpand;

    if (isExpanded) {
      // If clicked item is already expanded, collapse it
      newExpand = expand.filter((item) => item !== value);
    } else {
      // If clicked item is not expanded, collapse all others and expand it
      newExpand = [value];
    }

    setExpand(newExpand);
  };

  // *For Sub Child Active
  const handleSubChildActive = (item) => {
    return (
      item.childRoute?.indexOf(pathname) !== -1 &&
      item.childRoute?.indexOf(pathname)
    );
  };
  useEffect(() => {
    getWebTokens();
  }, []);

  return (
    <>
      {/* ========== Confirmation Dialog ========== */}
      <ConfirmationDialog
        open={confirmationDialog}
        onClose={() => setConfirmationDialog(false)}
        message={"Are you sure you want to logout?"}
        action={() => {
          setConfirmationDialog(false);
          dispatch(
            setEmployeeAuthentication(false)
          );
          userLogout();
          handleLogout();
        }}
      />
      {/* Vin Lot Modal */}
      <SimpleDialog
        open={vinDialog}
        onClose={() => setVinDialog(false)}
        title={"Search By Vin/Lot"}
      >
        <Box component="form" onSubmit={handleSubmit(applyFilter)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <InputField
                size={"small"}
                label={"Search"}
                placeholder={"Search"}
                register={register("search")}
              />
            </Grid>

            <Grid item xs={12} sm={12} sx={{ mt: 2, textAlign: "center" }}>
              <PrimaryButton title="Submit" type="submit" />
            </Grid>
          </Grid>
        </Box>
      </SimpleDialog>

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Box
            component={"img"}
            src={Images.sidebarLogo}
            sx={{ width: "180px" }}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <MenuOpenIcon
            onClick={onClick}
            sx={{ fontSize: "25px", cursor: "pointer", color: Colors.white }}
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mt: "20%" }}>
        <Avatar
          src={process.env.REACT_APP_IMAGE_BASE_URL + user?.picture}
          sx={{ width: "60px", height: "60px" }}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Typography
          variant="body2"
          sx={{ color: Colors.white, fontWeight: 300, fontSize: "18px" }}
        >
          {user?.name}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "8px",
          mt: "8%",
        }}
      >
        <Typography
          variant="body2"
          sx={{ color: Colors.white, fontWeight: "300 !important" }}
        >
          {user?.user_type === "A"
            ? "Admin ID  "
            : user?.user_type === "S"
              ? "Staff ID  "
              : "Customer ID"}
        </Typography>
        <Typography variant="body2" sx={{ color: Colors.white }}>
          {user?.ref_id}
        </Typography>
      </Box>
      <List>
        {user?.user_type == "C" ? (
          <ListItemButton
            component={Link}
            to={"/dashboard"}
            aria-label={"dashboard"}
            sx={{
              borderRadius: 2,
              px: 1,
              py: 0,
              mt: 1.5,
              bgcolor: "/dashboard" === pathname ? Colors.primary : "",
              fontWeight: "/dashboard" === pathname ? "bold" : "",
              boxShadow:
                "/dashboard" === pathname ? "0px 4px 5px 0px #B8B8B840" : "",
              "&:hover": {
                bgcolor: Colors.primary,
                ".MuiTypography-root": {
                  color: Colors.white,
                },
                ".MuiListItemIcon-root": {
                  bgcolor: "transparent",
                },
                svg: {
                  path: {
                    fill: Colors.white,
                  },
                },
              },
            }}
          >
            <ListItemIcon
              dangerouslySetInnerHTML={{ __html: SvgIcon["home"] }}
              sx={{
                minWidth: "auto",
                mr: 1,
                borderRadius: "6px",
                p: "10px",
                svg: {
                  height: "20px",
                  width: "20px",
                  path: {
                    fill: "/dashboard" === pathname ? Colors.white : "",
                  },
                },
              }}
            ></ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant="body2"
                  sx={{
                    textTransform: "capitalize",
                    fontFamily: FontFamily.NunitoRegular,
                    color:
                      "/dashboard" === pathname ? Colors.white : Colors.white,
                  }}
                >
                  Dashboard
                </Typography>
              }
            />
          </ListItemButton>
        ) : (
          myNavigation?.map((item, index) => (
            <Fragment key={index}>
              <ListItemButton
                key={index}
                component={item.route ? Link : "div"}
                to={item.route ?? ""}
                aria-label={item?.name}
                onClick={() => {
                  item.childRoute && handleCollapse(item.name);
                }}
                sx={{
                  borderRadius: 2,
                  px: 1,
                  py: 0,
                  mt: 1.5,
                  bgcolor:
                    item.route === pathname ||
                      handleSubChildActive(item) ||
                      handleSubChildActive(item) === 0
                      ? Colors.primary
                      : "",
                  boxShadow:
                    item.route === pathname ||
                      handleSubChildActive(item) ||
                      handleSubChildActive(item) === 0
                      ? "0px 4px 5px 0px #B8B8B840"
                      : "",
                  "&:hover": {
                    bgcolor: Colors.primary,
                    ".MuiTypography-root": {
                      color: Colors.white,
                    },
                    ".MuiListItemIcon-root": {
                      bgcolor: "transparent",
                    },
                    svg: {
                      path: {
                        fill: Colors.white,
                      },
                    },
                  },
                }}
              >
                <ListItemIcon
                  dangerouslySetInnerHTML={{ __html: SvgIcon[item.icon] }}
                  sx={{
                    bgcolor:
                      item.route === pathname ||
                        handleSubChildActive(item) ||
                        handleSubChildActive(item) === 0
                        ? "transparent"
                        : "transparent",
                    // boxShadow: "0px 4px 5px 0px #B8B8B840",
                    minWidth: "auto",
                    mr: 1,
                    borderRadius: "6px",
                    p: "10px",
                    svg: {
                      height: "20px",
                      width: "20px",
                      path: {
                        fill:
                          item.route === pathname ||
                            handleSubChildActive(item) ||
                            handleSubChildActive(item) === 0
                            ? Colors.white
                            : Colors.white,
                      },
                    },
                  }}
                ></ListItemIcon>

                <ListItemText
                  primary={
                    <Typography
                      variant="body2"
                      sx={{
                        textTransform: "capitalize",
                        fontFamily: FontFamily.NunitoRegular,
                        color:
                          item.route === pathname ||
                            handleSubChildActive(item) ||
                            handleSubChildActive(item) === 0
                            ? Colors.white
                            : Colors.white,
                      }}
                    >
                      {item.name}
                    </Typography>
                  }
                />
                {item?.children &&
                  (expand.indexOf(item.name) !== -1 ||
                    item.childRoute?.indexOf(pathname) !== -1 ? (
                    <ExpandLess
                      size="small"
                      sx={{
                        color:
                          item.route === pathname ||
                            handleSubChildActive(item) ||
                            handleSubChildActive(item) === 0
                            ? Colors.white
                            : Colors.white,
                      }}
                    />
                  ) : (
                    <ExpandMore size="small" sx={{ color: Colors.white }} />
                  ))}
              </ListItemButton>
              {item?.children && (
                <Collapse
                  in={
                    expand.indexOf(item.name) !== -1 ||
                      item.childRoute?.indexOf(pathname) !== -1
                      ? true
                      : false
                  }
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {item.children.map((child, i) => (
                      <ListItemButton
                        key={i}
                        component={child.route ? Link : "div"}
                        to={child.route ?? ""}
                        aria-label={child?.name}
                        onClick={() => {
                          child.route && handleCollapse(child.name);
                        }}
                        sx={{
                          borderRadius: 2,
                          px: 1,
                          py: 0.5,
                          mt: 0.5,
                          ml: "48px",
                          "&:hover": {
                            ".MuiTypography-root": {
                              color: Colors.white,
                            },
                          },
                        }}
                      >
                        <ListItemText
                          onClick={
                            child?.name == "Search Vin/Lot"
                              ? handleClick
                              : () => { }
                          }
                          primary={
                            <Typography
                              variant="body2"
                              sx={{
                                textTransform: "capitalize",
                                fontFamily: FontFamily.NunitoRegular,
                                color:
                                  child.route === pathname ||
                                    handleSubChildActive(child) ||
                                    handleSubChildActive(child) === 0
                                    ? Colors.white
                                    : Colors.white,
                                fontWeight:
                                  child.route === pathname ||
                                    handleSubChildActive(child) ||
                                    handleSubChildActive(child) === 0
                                    ? "bold"
                                    : "",
                              }}
                            >
                              {child.name}
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              )}
            </Fragment>
          ))
        )}

        {user?.user_type == "C" &&
          user?.customer_type_id == 3 &&
          user?.journey?.request?.status == "Completed" &&
          subCustomer.map((item, index) => (
            <Fragment key={index}>
              <ListItemButton
                key={index}
                component={item.route ? Link : "div"}
                to={item.route ?? ""}
                aria-label={item?.name}
                onClick={() => {
                  item.childRoute && handleCollapse(item.name);
                }}
                sx={{
                  borderRadius: 2,
                  px: 1,
                  py: 0,
                  mt: 1.5,
                  bgcolor: item?.route === pathname ? Colors.primary : "",
                  boxShadow:
                    item?.route === pathname ? "0px 4px 5px 0px #B8B8B840" : "",
                }}
              >
                <ListItemIcon
                  dangerouslySetInnerHTML={{ __html: SvgIcon[item.icon] }}
                  sx={{
                    // boxShadow: "0px 4px 5px 0px #B8B8B840",
                    minWidth: "auto",
                    mr: 1,
                    borderRadius: "6px",
                    p: "10px",
                    svg: {
                      height: "20px",
                      width: "20px",
                      path: {
                        fill:
                          item.route === pathname ||
                            handleSubChildActive(item) ||
                            handleSubChildActive(item) === 0
                            ? Colors.white
                            : "",
                      },
                    },
                  }}
                ></ListItemIcon>

                <ListItemText
                  primary={
                    <Typography
                      variant="body2"
                      sx={{
                        textTransform: "capitalize",
                        fontFamily: FontFamily.NunitoRegular,
                        color:
                          item.route === pathname ||
                            handleSubChildActive(item) ||
                            handleSubChildActive(item) === 0
                            ? Colors.white
                            : Colors.white,
                        fontWeight: item.route === pathname ? "bold" : "",
                      }}
                    >
                      {item.name}
                    </Typography>
                  }
                />
              </ListItemButton>
              {item?.children && (
                <Collapse
                  in={
                    expand.indexOf(item.name) !== -1 ||
                      item.childRoute?.indexOf(pathname) !== -1
                      ? true
                      : false
                  }
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {item.children.map((child, i) => (
                      <ListItemButton
                        key={i}
                        component={child.route ? Link : "div"}
                        to={child.route ?? ""}
                        aria-label={child?.name}
                        onClick={() => {
                          child.route && handleCollapse(child.name);
                        }}
                        sx={{
                          borderRadius: 2,
                          px: 1,
                          py: 0.5,
                          mt: 0.5,
                          ml: "48px",
                          "&:hover": {
                            ".MuiTypography-root": {
                              color: Colors.primary,
                            },
                          },
                        }}
                      >
                        <ListItemText
                          primary={
                            <Typography
                              variant="body2"
                              sx={{
                                textTransform: "capitalize",
                                fontFamily: FontFamily.NunitoRegular,
                                color:
                                  child.route === pathname ||
                                    handleSubChildActive(child) ||
                                    handleSubChildActive(child) === 0
                                    ? Colors.white
                                    : Colors.white,
                                fontWeight:
                                  child.route === pathname ||
                                    handleSubChildActive(child) ||
                                    handleSubChildActive(child) === 0
                                    ? "bold"
                                    : "",
                              }}
                            >
                              {child.name}
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              )}
            </Fragment>
          ))}
        {user?.user_type != "C" &&
          navigation.map((item, index) => {
            const filterItem = item?.children?.filter((i) => i.name !== "Payment History")
            return (
              <Fragment key={index}>
                <ListItemButton
                  key={index}
                  component={item.route ? Link : "div"}
                  to={item.route ?? ""}
                  aria-label={item?.name}
                  onClick={() => {
                    item.childRoute && handleCollapse(item.name);
                  }}
                  sx={{
                    borderRadius: 2,
                    px: 1,
                    py: 0,
                    mt: 1.5,
                    bgcolor:
                      item.route === pathname ||
                        handleSubChildActive(item) ||
                        handleSubChildActive(item) === 0
                        ? Colors.primary
                        : "",
                    boxShadow:
                      item.route === pathname ||
                        handleSubChildActive(item) ||
                        handleSubChildActive(item) === 0
                        ? "0px 4px 5px 0px #B8B8B840"
                        : "",
                    "&:hover": {
                      bgcolor: Colors.primary,
                      ".MuiTypography-root": {
                        color: Colors.white,
                      },
                      ".MuiListItemIcon-root": {
                        bgcolor: "transparent",
                      },
                      svg: {
                        path: {
                          fill: Colors.white,
                        },
                      },
                    },
                  }}
                >
                  <ListItemIcon
                    dangerouslySetInnerHTML={{ __html: SvgIcon[item.icon] }}
                    sx={{
                      bgcolor:
                        item.route === pathname ||
                          handleSubChildActive(item) ||
                          handleSubChildActive(item) === 0
                          ? "transparent"
                          : "transparent",
                      // boxShadow: "0px 4px 5px 0px #B8B8B840",
                      minWidth: "auto",
                      mr: 1,
                      borderRadius: "6px",
                      p: "10px",
                      svg: {
                        height: "20px",
                        width: "20px",
                        path: {
                          fill:
                            item.route === pathname ||
                              handleSubChildActive(item) ||
                              handleSubChildActive(item) === 0
                              ? Colors.white
                              : "",
                        },
                      },
                    }}
                  ></ListItemIcon>

                  <ListItemText
                    primary={
                      <Typography
                        variant="body2"
                        sx={{
                          textTransform: "capitalize",
                          fontFamily: FontFamily.NunitoRegular,
                          color:
                            item.route === pathname ||
                              handleSubChildActive(item) ||
                              handleSubChildActive(item) === 0
                              ? Colors.white
                              : Colors.white,
                        }}
                      >
                        {item.name}
                      </Typography>
                    }
                  />
                  {item?.children &&
                    (expand.indexOf(item.name) !== -1 ||
                      item.childRoute?.indexOf(pathname) !== -1 ? (
                      <ExpandLess
                        size="small"
                        sx={{
                          color:
                            item.route === pathname ||
                              handleSubChildActive(item) ||
                              handleSubChildActive(item) === 0
                              ? Colors.white
                              : Colors.white,
                        }}
                      />
                    ) : (
                      <ExpandMore size="small" sx={{ color: Colors.white }} />
                    ))}
                </ListItemButton>
                {item?.children && (
                  <Collapse
                    in={
                      expand.indexOf(item.name) !== -1 ||
                        item.childRoute?.indexOf(pathname) !== -1
                        ? true
                        : false
                    }
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {filterItem?.map((child, i) => (
                        <ListItemButton
                          key={i}
                          component={child.route ? Link : "div"}
                          to={child.route ?? ""}
                          aria-label={child?.name}
                          onClick={() => {
                            child.route && handleCollapse(child.name);
                          }}
                          sx={{
                            borderRadius: 2,
                            px: 1,
                            py: 0.5,
                            mt: 0.5,
                            ml: "48px",
                            "&:hover": {
                              ".MuiTypography-root": {
                                color: Colors.primary,
                              },
                            },
                          }}
                        >
                          <ListItemText
                            primary={
                              <Typography
                                variant="body2"
                                sx={{
                                  textTransform: "capitalize",
                                  fontFamily: FontFamily.NunitoRegular,
                                  color:
                                    child.route === pathname ||
                                      handleSubChildActive(child) ||
                                      handleSubChildActive(child) === 0
                                      ? Colors.white
                                      : Colors.white,
                                  fontWeight:
                                    child.route === pathname ||
                                      handleSubChildActive(child) ||
                                      handleSubChildActive(child) === 0
                                      ? "bold"
                                      : "",
                                }}
                              >
                                {child.name}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                )}
              </Fragment>
            )
          })}
      </List>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 2,
          ":hover": { color: Colors.white },
        }}
      >
        <Button
          className="logout_Box"
          variant="contained"
          onClick={() => setConfirmationDialog(true)}
          sx={{
            backgroundColor: "white",
            color: Colors.primary,
            textTransform: "none",
            borderRadius: "12px",
            p: "9px 17px 7px 17px",
            ":hover": { color: Colors.white },
          }}
        >
          <Box sx={{ display: "flex", gap: 2 }}>
            <LogoutIcon
              className="logout_Icon"
              sx={{ color: Colors.primary, fontSize: "20px" }}
            />
            <Box>Logout</Box>
          </Box>
        </Button>
        {/* <PrimaryButton
        
          title="Logout"
          type="button"
          bgcolor='#FFF'

          buttonStyle={{
            justifyContent: "space-evenly",
            path: { fill: Colors.primary },
            color:Colors.primary
            
          }}
          startIcon={<LogoutIcon  />}
          onClick={() => setConfirmationDialog(true)}
        /> */}
      </Box>
    </>
  );
}

export default Sidebar;
