import { post, patch, get } from 'services';
import routes from './routes';

const ClosingPeriodServices = {
  createPeriod: async (obj) => {
    const data = await post(routes.createPeriod, obj);
    return data;
  },
  UpdateClosePeriod: async (obj) => {
    const data = await patch(routes.closePeriod, obj);
    return data;
  },
  UpdateClosingPeriod: async (obj) => {
    const data = await patch(routes.updateClosingPeriod, obj);
    return data;
  },
  getPeriods: async (params) => {
    const data = await get(routes.getPeriods, params);
    return data;
  },
  getAccounts: async (params) => {
    const data = await get(routes.getAccounts, params);
    return data;
  },
  getAllClosing: async (params) => {
    const data = await get(routes.getAllClosing, params);
    return data;
  },
}

export default ClosingPeriodServices