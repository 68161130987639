import React, { useRef, useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Edit, Delete } from "@mui/icons-material";
import MessageIcon from "@mui/icons-material/Message";
import { CashIcon, CreateIcon, EyeIcon, RequestBuyerIdIcon } from "assets";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import WhatsApp from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import { Link } from "react-router-dom";
import Colors from "assets/Style/Colors";
import { useAuth } from "context/UseContext";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { LuDownload } from "react-icons/lu";
import moment from "moment";

const DropdownMenu = ({
  item,
  permissions,
  navigate,
  setOpenDelete,
  setShippingId,
  ErrorToaster,
  isOpen,
  onToggle,
  onClick1,
  onClick2,
  excelDownload,
  onAllocateClick,
  onMessageClick,
  id,
  isDelete,
  onDeleteClick,
  isEdit,
  viewInvoice,
  invoice,
  deleteInvoice,
  whatsAppClick,
  emailClick,
  isForward,
  onEdit,
  vccIssue,
  depositView,
  voucher,
  voucherView,
  reverseVoucher,
  iftv,
  client,
  isValid,
  isGpDelete,
  addCustomVat,
  customVat,
  handleCharge,
  handleReverse,
  showVendorData,
  isRole,
  isBuyerId,
  vendorApplyFund,
  vendorViewDetail,
  onViewDetail,
  vendorDeleteIcon,
  isAdmin,
  vendorEdit,
  onClickReverseVCC,
  onClickReverseEP,
  onClickCustomCharge,
  onClickDeleteVCC,
  onClickDeleteIssueMobaya,
  onClickReverseIssueMobaya,
  receiveCec,
  salesCertificate,
  importCustomer,
  route,
  vehicleTT,
  vendorPlan,
  reverseEP,
  deleteReferri,
}) => {
  const dropdownRef = useRef(null);
  const { user } = useAuth();
  // Close dropdown when clicking outside
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);

  const date = moment(item?.vcc_expiry_date).format("DD-MM-YYYY");
  const targetDate = moment(date, "DD-MM-YYYY");
  let daysRemaining = targetDate.diff(
    item?.exit_paper_received_at || moment(),
    "days"
  );
  if (daysRemaining < 0) {
    daysRemaining = 0;
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onToggle(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onToggle]);
  const closeMenu = () => onToggle(false);
  return (
    <div ref={dropdownRef} className="dropdown-container">
      <div onClick={() => onToggle(!isOpen)} className="dropdown-button">
        <MoreVertIcon />
      </div>

      {isOpen && (
        <div className="dropdown-menu">


          {/* Vendor Plans Options*/}
          {vendorPlan && <>
            {(permissions?.edit_plan || user?.user_type == 'A') && <div
              onClick={() => {
                onEdit()
                closeMenu();
              }}
              className="dropdown-item"
            >
              <IconButton
                sx={{

                }}
                className="icon"
              >
                <Edit
                  sx={{
                    color: Colors.black,
                    height: "20px !important",
                  }}
                />
              </IconButton>
              <span>Edit </span>
            </div>}
            {(permissions?.delete_plan || user?.user_type == 'A') && <div
              onClick={() => {
                onDeleteClick();
                closeMenu()
                // setConfirmationDialog(true);
                // setBuyerId(item.id);
              }}
              className="dropdown-item"
            >
              <IconButton
                sx={{
                  // bgcolor: Colors.danger,
                  // "&:hover": {
                  //   bgcolor: Colors.danger,
                  // },
                }}
                className="icon"
              >
                <Delete
                  sx={{
                    color: Colors.danger,
                    height: "20px !important",
                  }}
                />
              </IconButton>
              <span>Delete</span>
            </div>}
            <div
              onClick={() => {
                onClick2()
                closeMenu()
              }}
              className="dropdown-item"
            >
              <IconButton
                sx={{

                }}
                className="icon"
              >
                <LuDownload />

              </IconButton>
              <span>PDF</span>
            </div>
            <div
              onClick={() => {
                excelDownload()
                closeMenu()
              }}
              className="dropdown-item"
            >
              <IconButton
                sx={{

                }}
                className="icon"
              >
                <LuDownload />

              </IconButton>
              <span>Excel</span>
            </div>

          </>
          }
          {/* Add Custom And Vat Options*/}
          {customVat && (
            <>
              {(item?.arrived_port_date && item?.vcc?.id) ||
                item?.vcc?.custom_income_aed > 0 ||
                item?.vcc?.cv_charged_directly ? (
                <Tooltip
                  title={
                    item?.vcc?.vcc_purpose
                      ? "VCC Purpose already added so Can't Update"
                      : item?.vcc?.cv_charged_directly
                        ? "Custom & VAT already charged"
                        : ""
                  }
                >
                  <Box
                    sx={{
                      cursor: item?.vcc?.vcc_purpose
                        ? "not-allowed"
                        : "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                    className="dropdown-item"
                    onClick={() => {
                      addCustomVat();
                      closeMenu();
                    }}
                  >
                    <IconButton
                      sx={{
                        bgcolor:
                          item?.vcc?.vcc_purpose ||
                            item?.vcc?.cv_charged_directly
                            ? Colors.grey
                            : Colors.blackShade,
                        "&:hover": {
                          bgcolor:
                            item?.vcc?.vcc_purpose ||
                              item?.vcc?.cv_charged_directly
                              ? Colors.grey
                              : Colors.blackShade,
                        },
                        cursor:
                          item?.vcc?.vcc_purpose ||
                            item?.vcc?.cv_charged_directly
                            ? "not-allowed"
                            : "pointer",
                      }}
                      className="icon"
                      disabled={
                        !!(
                          item?.vcc?.vcc_purpose ||
                          item?.vcc?.cv_charged_directly
                        )
                      }
                    >
                      <Edit
                        sx={{
                          color:
                            item?.vcc?.vcc_purpose ||
                              item?.vcc?.cv_charged_directly
                              ? Colors.grey
                              : Colors.white,
                          height: "16px !important",
                        }}
                      />
                    </IconButton>
                    <Typography
                      variant="body2"
                      sx={{
                        color:
                          item?.vcc?.vcc_purpose ||
                            item?.vcc?.cv_charged_directly
                            ? Colors.grey
                            : "inherit",
                      }}
                    >
                      Add Custom & VAT
                    </Typography>
                  </Box>
                </Tooltip>
              ) : null}
              {(permissions['charge_C&V'] || isAdmin) && <Tooltip
                title={
                  !item?.vcc?.custom_charges_aed
                    ? "Custom charges not added"
                    : item?.vcc?.cv_charged_directly
                      ? "Custom & VAT already charged"
                      : item?.vcc?.vcc_purpose
                        ? "VCC Purpose already added so Can't Update" : ""
                }
                arrow
              >
                <div className="dropdown-item">
                  <Box
                    component="div"
                    className="pdf-hide"
                    style={
                      !item?.vcc?.custom_charges_aed ||
                        item?.vcc?.cv_charged_directly || item?.vcc?.vcc_purpose
                        ? { opacity: 0.5, pointerEvents: "none" } // Disable button style
                        : {}
                    }
                    onClick={() => {
                      if (
                        item?.vcc?.custom_charges_aed &&
                        !item?.vcc?.cv_charged_directly && !item?.vcc?.vcc_purpose
                      ) {
                        handleCharge();
                        closeMenu();
                      }
                    }}
                  >
                    <IconButton
                      sx={{
                        bgcolor:
                          !item?.vcc?.custom_charges_aed ||
                            item?.vcc?.cv_charged_directly
                            ? Colors.grey // Disabled state color
                            : Colors.primary, // Enabled state color
                        "&:hover": {
                          bgcolor:
                            !item?.vcc?.custom_charges_aed ||
                              item?.vcc?.cv_charged_directly || item?.vcc?.vcc_purpose
                              ? Colors.grey
                              : Colors.primary,
                        },
                        p: "8px",
                      }}
                    >
                      <Edit
                        sx={{
                          color: Colors.white,
                          height: "16px !important",
                        }}
                      />
                    </IconButton>
                    <Typography variant="body2">Charge Custom & VAT</Typography>
                  </Box>
                </div>
              </Tooltip>}

              {(permissions['reverse_C&V'] || isAdmin) && (
                <Tooltip
                  title={
                    !item?.vcc?.cv_charged_directly
                      ? "Custom & VAT not charged"
                      : item?.vcc?.vcc_purpose !== null
                        ? "VCC Purpose already added so Can't Reversed"
                        : ""
                  }
                  arrow
                >
                  <div className="dropdown-item">
                    <Box
                      component="div"
                      className="pdf-hide"
                      style={
                        !item?.vcc?.cv_charged_directly ||
                          item?.vcc?.vcc_purpose !== null
                          ? { opacity: 0.5, pointerEvents: "none" }
                          : {}
                      }
                      onClick={() => {
                        if (item?.vcc?.cv_charged_directly) {
                          handleReverse();
                          closeMenu();
                        }
                      }}
                    >
                      <IconButton
                        sx={{
                          bgcolor:
                            !item?.vcc?.cv_charged_directly ||
                              item?.vcc?.vcc_purpose !== null
                              ? Colors.grey
                              : Colors.primary,
                          "&:hover": {
                            bgcolor:
                              !item?.vcc?.cv_charged_directly ||
                                item?.vcc?.vcc_purpose !== null
                                ? Colors.grey
                                : Colors.primary,
                          },
                          p: "8px",
                        }}
                      // disabled={!item?.vcc?.cv_charged_directly}
                      >
                        <Edit
                          sx={{
                            color: Colors.white,
                            height: "16px !important",
                          }}
                        />
                      </IconButton>
                      <Typography variant="body2">
                        Reverse Custom & VAT
                      </Typography>
                    </Box>
                  </div>
                </Tooltip>
              )}
              {(permissions?.["delete-C&V"] || isAdmin) && (
                <Tooltip
                  title={
                    item?.vcc?.vcc_purpose
                      ? "VCC Purpose already added so Can't Delete"
                      : item?.vcc?.cv_charged_directly
                        ? "Custom & VAT already charged"
                        : ""
                  }
                  arrow
                >
                  <div
                    onClick={() => {
                      if (
                        item?.vcc?.vcc_purpose == null ||
                        !item?.vcc?.cv_charged_directly || !item?.vcc?.vcc_purpose
                      ) {
                        onDeleteClick();
                        closeMenu();
                      }
                      // setConfirmationDialog(true);
                      // setBuyerId(item.id);
                    }}
                    className="dropdown-item"
                  >
                    <IconButton
                      sx={{
                        bgcolor:
                          item?.vcc?.vcc_purpose ||
                            item?.vcc?.cv_charged_directly
                            ? Colors.grey
                            : Colors.danger,
                        "&:hover": {
                          bgcolor:
                            item?.vcc?.vcc_purpose ||
                              item?.vcc?.cv_charged_directly
                              ? Colors.grey
                              : Colors.danger,
                        },
                        cursor: item?.vcc?.vcc_purpose
                          ? "not-allowed"
                          : "pointer",
                        p: "8px",
                      }}
                      className="icon"
                    >
                      <Delete
                        sx={{
                          color: Colors.white,
                          height: "16px !important",
                        }}
                      />
                    </IconButton>
                    <span>Delete Custom & VAT</span>
                  </div>
                </Tooltip>
              )}
            </>
          )}
          {/* Update buyer id */}
          {permissions?.update && !isEdit && !importCustomer && (
            <div
              onClick={() => {
                onClick1(id);
                closeMenu();
              }}
              className="dropdown-item"
            >
              <IconButton
                sx={{
                  bgcolor: Colors.bluishCyan,
                  "&:hover": {
                    bgcolor: Colors.bluishCyan,
                  },
                }}
                className="icon"
              >
                <Edit
                  sx={{
                    color: Colors.white,
                    height: "16px !important",
                  }}
                />
              </IconButton>
              <span>Edit </span>
            </div>
          )}
          {/* Edit Import Customer */}
          {permissions && (permissions['edit_import_customer'] || user?.user_type == 'A') && importCustomer && (
            <div
              onClick={() => {
                onClick1(id);
                closeMenu();
              }}
              className="dropdown-item"
            >
              <IconButton
                sx={{
                  bgcolor: Colors.bluishCyan,
                  "&:hover": {
                    bgcolor: Colors.bluishCyan,
                  },
                }}
                className="icon"
              >
                <Edit
                  sx={{
                    color: Colors.white,
                    height: "16px !important",
                  }}
                />
              </IconButton>
              <span>Edit</span>
            </div>
          )}
          {/*  Permissions role permission */}

          {permissions?.permission && (
            <div
              onClick={() =>
                navigate(`/permission/${item?.id}`, { state: item })
              }
              className="dropdown-item"
            >
              <IconButton
                sx={{
                  bgcolor: Colors.blackShade,
                  "&:hover": {
                    bgcolor: Colors.blackShade,
                  },
                }}
                className="icon"
              >
                <RequestBuyerIdIcon />
              </IconButton>
              <Typography variant="body2">Permission</Typography>
            </div>
          )}
          {/* Delete buyer id and Delete role permission */}
          {permissions?.delete && viewInvoice !== "invoice" && (
            <div
              onClick={() => {
                onDeleteClick();
                // setConfirmationDialog(true);
                // setBuyerId(item.id);
              }}
              className="dropdown-item"
            >
              <IconButton
                sx={{
                  bgcolor: Colors.danger,
                  "&:hover": {
                    bgcolor: Colors.danger,
                  },
                }}
                className="icon"
              >
                <Delete
                  sx={{
                    color: Colors.white,
                    height: "16px !important",
                  }}
                />
              </IconButton>
              <span>Delete</span>
            </div>
          )}
          {/* View Invoice Option */}
          {invoice && (
            <Link
              to={`/${viewInvoice}/${id}`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={closeMenu}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 16px",
                cursor: "pointer",
                color: "#000",
                textDecoration: "none",
                backgroundColor: isHovered ? "#eff9e8" : "transparent",
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <VisibilityIcon style={{ marginRight: "5px" }} />
              <span>View</span>
            </Link>
          )}
          {/* {invoice &&
                        <div
                            onClick={() => {
                                window.open(viewInvoice(id))
                                closeMenu();
                            }}
                            className="dropdown-item"
                        >
                            <VisibilityIcon className='icon' />
                            <span>View</span>
                        </div>
                    } */}


          {/* Vehicle TT delete and Edit button */}
          {vehicleTT && (
            <>
              <Link
                to={`/buyer-tt-detail/${id}`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={closeMenu}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 16px",
                  cursor: "pointer",
                  textDecoration: "none",
                  color: "#000",
                  backgroundColor: isHovered2 ? "#eff9e8" : "transparent",
                }}
                onMouseEnter={() => setIsHovered2(true)}
                onMouseLeave={() => setIsHovered2(false)}
              >
                <VisibilityIcon style={{ marginRight: "5px" }} />
                <span>VBI. Plan</span>
              </Link>
              <div
                onClick={() => {
                  if (item?.status == 'draft') {
                    onClick1(id);
                  }
                  closeMenu();
                }}
                className="dropdown-item"
                style={{
                  cursor:
                    item?.status == 'draft'
                      ? "pointer"
                      : "not-allowed",
                  opacity:
                    item?.status == 'draft'
                      ? 1
                      : 0.6,
                }}
              >
                <IconButton
                  sx={{
                    bgcolor: Colors.bluishCyan,
                    "&:hover": {
                      bgcolor: Colors.bluishCyan,
                    },
                  }}
                  className="icon"
                >
                  <Edit
                    sx={{
                      color: Colors.white,
                      height: "16px !important",
                    }}
                  />
                </IconButton>
                <span>Edit </span>
              </div>
            </>
          )}
          {(permissions?.delete_vehicle_tt || user?.user_type == 'A') &&
            vehicleTT && (
              <div
                onClick={() => {
                  deleteInvoice(item?.id);
                  closeMenu();
                }}
                className="dropdown-item"
                style={{
                  cursor: "pointer",
                }}
              >
                <Delete
                  sx={{
                    color: Colors.danger,
                  }}
                  className="icon"
                />
                <span>Delete</span>
              </div>
            )}

          {/* Delete Vcc Option */}
          {(permissions?.["delete-vcc"] || (isAdmin && vccIssue)) && (
            <Tooltip
              title={
                item?.exit_paper_received ||
                  item?.makasa_received ||
                  item?.vehicle?.mobaya_reference
                  ? "VCC Document Issued/Received"
                  : ""
              }
            >
              <div
                onClick={(e) => {
                  if (
                    item?.exit_paper_received ||
                    item?.makasa_received ||
                    item?.vehicle?.mobaya_reference
                  ) {
                    e.stopPropagation();
                    return;
                  }
                  deleteInvoice(
                    item?.id,
                    item?.booking_id,
                    item?.booking?.customer_id
                  );
                  closeMenu();
                }}
                className="dropdown-item"
                style={{
                  cursor:
                    item?.exit_paper_received ||
                      item?.makasa_received ||
                      item?.vehicle?.mobaya_reference
                      ? "not-allowed"
                      : "pointer",
                  opacity:
                    item?.exit_paper_received ||
                      item?.makasa_received ||
                      item?.vehicle?.mobaya_reference
                      ? 0.6
                      : 1,
                }}
              >
                <Delete
                  sx={{
                    color:
                      item?.exit_paper_received ||
                        item?.makasa_received ||
                        item?.vehicle?.mobaya_reference
                        ? Colors.grey
                        : Colors.danger,
                  }}
                  className="icon"
                />
                <span>Delete</span>
              </div>
            </Tooltip>
          )}

          {/* Delete depositView Option */}

          {depositView && (
            <Box
              component={"div"}
              className="pdf-hide dropdown-item"
              sx={{ gap: "16px !important" }}
            >
              <Box
                onClick={() =>
                  window.open(`${route}/${id}`, "_blank")
                }
              >
                <IconButton
                  sx={{
                    bgcolor: Colors.primary,
                    "&:hover": { bgcolor: Colors.primary },
                  }}
                >
                  <EyeIcon />
                </IconButton>
                <Typography variant="body2">View</Typography>
              </Box>
            </Box>
          )}
          {depositView && (permissions || isAdmin) && (
            <div
              onClick={() => {
                deleteInvoice(item?.vcc?.id);
                closeMenu();
              }}
              className="dropdown-item"
            >
              <Delete sx={{ color: Colors.danger }} className="icon" />
              <span>Delete</span>
            </div>
          )}

          {/* Create Gate Pass Button */}
          {((permissions?.create &&
            item?.booking?.customer?.customerProfile?.is_trusted &&
            !item?.gate_pass) ||
            (item?.gate_pass?.is_approved &&
              item?.gate_pass?.is_paid === null &&
              item?.gate_pass?.is_valid === null &&
              item?.gate_pass?.total_due === null &&
              item?.gate_pass?.is_out !== true)) && (
              <div className="dropdown-item">
                <Box
                  sx={{ display: "flex", alignItems: "center" }}
                  onClick={() => {
                    navigate(`/create-gate-pass/${item.id}`);
                    closeMenu();
                  }}
                >
                  <IconButton
                    sx={{
                      bgcolor: Colors.primary,
                      "&:hover": { bgcolor: Colors.primary },
                      p: "8px",
                    }}
                  >
                    <CreateIcon />
                  </IconButton>
                  <Typography variant="body2">Create</Typography>
                </Box>
              </div>
            )}

          {/* ReCreate Gate Pass Button */}
          {permissions?.recreate &&
            item?.gate_pass?.is_valid === false &&
            item?.gate_pass?.total_due !== null &&
            item?.gate_pass?.is_out !== true && (
              <div className="dropdown-item">
                <Box
                  sx={{ display: "flex", alignItems: "center" }}
                  onClick={() => {
                    navigate(`/recreate-gate-pass/${item.id}`, {
                      state: { recreate: true },
                    });
                    closeMenu();
                  }}
                >
                  <IconButton
                    sx={{
                      bgcolor: Colors.primary,
                      "&:hover": { bgcolor: Colors.primary },
                      p: "8px",
                    }}
                  >
                    <CreateIcon />
                  </IconButton>
                  <Typography variant="body2">ReCreate</Typography>
                </Box>
              </div>
            )}

          {/* View Gate Pass Button */}
          {permissions?.view && item?.gate_pass?.total_due && (
            <div className="dropdown-item">
              <Box
                sx={{ display: "flex", alignItems: "center" }}
                onClick={() => {
                  onClick1();
                  closeMenu();
                }}
              >
                <IconButton
                  sx={{
                    bgcolor: Colors.primary,
                    "&:hover": { bgcolor: Colors.primary },
                    p: "8px",
                  }}
                >
                  <VisibilityIcon sx={{ fontSize: "18px", color: "#FFFFFF" }} />
                </IconButton>
                <Typography variant="body2">View</Typography>
              </Box>
            </div>
          )}

          {/* Payment Gate Pass Button */}
          {!isValid &&
            permissions?.payment &&
            item?.gate_pass?.total_due &&
            !item?.gate_pass?.is_paid &&
            (
              <div className="dropdown-item">
                <Box
                  sx={{ display: "flex", alignItems: "center" }}
                  onClick={() => {
                    navigate(`/gate-pass/${item.id}`, { state: "payment" });
                    closeMenu();
                  }}
                >
                  <IconButton
                    sx={{
                      bgcolor: Colors.smokeyGrey,
                      "&:hover": { bgcolor: Colors.smokeyGrey },
                      p: "8px",
                    }}
                  >
                    <CashIcon />
                  </IconButton>
                  <Typography variant="body2">Payment</Typography>
                </Box>
              </div>
            )}

          {voucher && (
            <>
              <div className="dropdown-item">
                <Box component={"div"} onClick={voucherView}>
                  <IconButton
                    sx={{
                      bgcolor: Colors.primary,
                      "&:hover": { bgcolor: Colors.primary },
                      p: "8px",
                    }}
                  >
                    <EyeIcon />
                  </IconButton>
                  <Typography variant="body2">View</Typography>
                </Box>
              </div>
              {(!iftv || isAdmin) && (
                <div className="dropdown-item">
                  <Box
                    component={"div"}
                    className="pdf-hide"
                    style={
                      client &&
                        parseFloat(item?.invoice?.balance).toFixed(2) ===
                        parseFloat(item?.invoice?.total).toFixed(2) || item?.is_reversed
                        ? {
                          opacity: 0.5,
                          pointerEvents: "none",
                        }
                        : {}
                    }
                    onClick={
                      (client &&
                        parseFloat(item?.invoice?.balance).toFixed(2) !==
                        parseFloat(item?.invoice?.total).toFixed(2)) ||
                        voucher
                        ? () => reverseVoucher(item)
                        : undefined
                    }
                  >
                    <IconButton
                      sx={{
                        bgcolor: Colors.primary,
                        "&:hover": { bgcolor: Colors.primary },
                        p: "8px",
                      }}
                    >
                      <Edit
                        sx={{
                          color: Colors.white,
                          height: "16px !important",
                        }}
                      />
                    </IconButton>
                    <Typography variant="body2">Reverse</Typography>
                  </Box>
                </div>
              )}
            </>
          )}
          {vendorViewDetail && (
            <Box
              onClick={() => {
                onClick1(item);
              }}
              className="dropdown-item"
            >
              <IconButton
                sx={{
                  bgcolor: Colors.primary,
                  "&:hover": {
                    bgcolor: Colors.primary,
                  },
                }}
              >
                <EyeIcon />
              </IconButton>
              <Typography variant="body2">Detail View</Typography>
            </Box>
          )}

          {vendorEdit && (
            <Box
              onClick={() => {
                onEdit(id, item);
              }}
              sx={{ display: "flex", justifyContent: "start !important" }}
              className="dropdown-item"
            >
              <IconButton
                sx={{
                  bgcolor: Colors.bluishCyan,
                  "&:hover": {
                    bgcolor: Colors.bluishCyan,
                  },
                }}
              >
                <Edit
                  sx={{
                    color: Colors.white,
                    height: "16px !important",
                  }}
                />
              </IconButton>
              <Typography variant="body2">Edit</Typography>
            </Box>
          )}

          {/* WhatsApp Option */}
          {isForward && !vccIssue && (
            <>
              <div className="dropdown-item">
                <Box
                  component={"div"}
                  onClick={() => {
                    whatsAppClick(id);
                    closeMenu();
                  }}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <IconButton
                    sx={{
                      bgcolor: Colors.primary,
                      "&:hover": { bgcolor: Colors.primary },
                      p: "8px",
                    }}
                  >
                    <WhatsApp sx={{ fontSize: "18px", color: "#FFFFFF" }} />
                  </IconButton>
                  <Typography variant="body2" sx={{ fontSize: "12px" }}>
                    WhatsApp
                  </Typography>
                </Box>
              </div>

              {/* Email Option */}
              <div className="dropdown-item">
                <Box
                  component={"div"}
                  onClick={() => {
                    emailClick();
                    closeMenu();
                  }}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <IconButton sx={{ p: "8px" }}>
                    <EmailIcon sx={{ fontSize: "18px", color: Colors.blue }} />
                  </IconButton>
                  <Typography variant="body2" sx={{ fontSize: "12px" }}>
                    Email
                  </Typography>
                </Box>
              </div>
            </>
          )}
          {/* Delete Invoice Option */}
          {(permissions?.["delete-payments-history"] || isAdmin) &&
            !client &&
            !customVat &&
            !depositView &&
            !vccIssue && (
              <Tooltip
                title={
                  item?.booking?.vehicle?.client_costings
                    ? "Client Costing Already Applied."
                    : ""
                }
                arrow
              >
                <div
                  onClick={() => {
                    if (!item?.booking?.vehicle?.client_costings) {
                      deleteInvoice(item?.id);
                      closeMenu();
                    }
                  }}
                  className="dropdown-item"
                  style={{
                    cursor: item?.booking?.vehicle?.client_costings ? "not-allowed" : "pointer",
                    opacity: item?.booking?.vehicle?.client_costings ? 0.5 : 1,
                  }}
                >
                  <Delete
                    sx={{
                      color: Colors.danger,
                    }}
                    className="icon"
                  />
                  <span>Delete</span>
                </div>
              </Tooltip>
            )}
          {/* Vouchers Options and Client Costing Options */}
          {(permissions?.["delete-unpaid-paid-invoices"] || isAdmin) &&
            !customVat &&
            !depositView &&
            !vccIssue && (
              <div className="dropdown-item">
                <Box
                  component={"div"}
                  onClick={() => {
                    if (
                      parseFloat(item?.invoice?.balance).toFixed(2) !==
                      parseFloat(item?.invoice?.total).toFixed(2)
                    ) {
                      onDeleteClick();
                    }
                  }}
                  sx={{ mr: "12px" }}
                >
                  <IconButton
                    sx={{
                      bgcolor:
                        parseFloat(item?.invoice?.balance).toFixed(2) ===
                          parseFloat(item?.invoice?.total).toFixed(2)
                          ? Colors.grey
                          : Colors.red,
                      "&:hover": {
                        bgcolor:
                          parseFloat(item?.invoice?.balance).toFixed(2) ===
                            parseFloat(item?.invoice?.total).toFixed(2)
                            ? Colors.grey
                            : Colors.red,
                      },
                    }}
                    disabled={
                      parseFloat(item?.invoice?.balance).toFixed(2) ===
                      parseFloat(item?.invoice?.total).toFixed(2)
                    }
                  >
                    <Delete />
                  </IconButton>
                  <Typography
                    variant="body2"
                    color={
                      parseFloat(item?.invoice?.balance).toFixed(2) ===
                        parseFloat(item?.invoice?.total).toFixed(2)
                        ? Colors.grey
                        : "inherit"
                    }
                  >
                    Delete
                  </Typography>
                </Box>
              </div>
            )}
          {permissions?.["delete-gp"] && (
            <Tooltip
              title={
                item?.gate_pass?.is_out
                  ? "This vehicle is already out from Galaxy yard"
                  : item?.gate_pass?.is_paid
                    ? "Gate pass for this vehicle has already been paid"
                    : ""
              }

            >
              <div
                className={`dropdown-item`}
                onClick={() => {
                  if (!item?.gate_pass?.is_out || !item?.gate_pass?.is_paid) {
                    onDeleteClick();
                  }
                }}
              >
                <IconButton
                  disabled={item?.gate_pass?.is_out || item?.gate_pass?.is_paid}
                  sx={{
                    bgcolor: item?.gate_pass?.is_out || item?.gate_pass?.is_paid ? Colors.grey : Colors.danger,
                    "&:hover": {
                      bgcolor: item?.gate_pass?.is_out || item?.gate_pass?.is_paid ? Colors.grey : Colors.danger,
                    },
                  }}
                  className="icon"
                >
                  <Delete
                    sx={{
                      color: Colors.white,
                      height: "16px !important",
                    }}
                  />
                </IconButton>
                <span>Delete</span>
              </div>
            </Tooltip>
          )}


          {/* View Detail Option */}
          {permissions?.detail_view && (
            <div
              onClick={() => {
                onClick1(id, item);
                closeMenu();
              }}
              className="dropdown-item"
            >
              <VisibilityIcon className="icon" />
              <span>View Detail</span>
            </div>
          )}
          {/* Buyer ID Detail Option */}
          {permissions?.detail_buyer && item?.request && (
            <div
              onClick={() => {
                navigate(`/buyer-id-detail/${item.id}`);
                closeMenu();
              }}
              className="dropdown-item"
            >
              <IconButton
                className="icon"
                sx={{
                  bgcolor: Colors.blackShade,
                  "&:hover": { bgcolor: Colors.blackShade },
                }}
              >
                <RequestBuyerIdIcon />
              </IconButton>
              <span>Buyer ID Detail</span>
            </div>
          )}
          {/* Edit Booked vehicle */}
          {permissions?.edit && (
            <div
              onClick={() => {
                onEdit(id, item);
              }}
              className="dropdown-item"
            >
              <IconButton
                sx={{
                  bgcolor: Colors.bluishCyan,
                  "&:hover": { bgcolor: Colors.bluishCyan },
                }}
                className="icon"
              >
                <Edit sx={{ color: Colors.white, height: "16px !important" }} />
              </IconButton>
              <span>Edit</span>
            </div>
          )}
          {/* Edit Container Option */}
          {permissions?.update_shipping && (
            <div
              onClick={() => {
                onClick2(id, item);
                closeMenu();
              }}
              className="dropdown-item"
            >
              <Edit className="icon" />
              <span>Edit Container</span>
            </div>
          )}

          {/* Allocate/De-allocate Option */}
          {permissions?.allocate && item?.request && (
            <div
              onClick={() => {
                onAllocateClick(item);
                closeMenu();
              }}
              className="dropdown-item"
            >
              <Edit className="icon" />
              <span>
                {item?.request?.allocation_status === "allocated" ||
                  item?.request?.allocation_status === "issued"
                  ? "De-Allocate"
                  : "Allocate"}
              </span>
            </div>
          )}

          {/* Message Option */}
          {permissions?.message && (
            <div
              onClick={() => {
                onMessageClick(item);
                closeMenu();
              }}
              className="dropdown-item"
            >
              <MessageIcon className="icon" />
              <span>Message</span>
            </div>
          )}
          {/* Request Buyer ID Option */}
          {permissions?.request && !item?.request && !isForward && (
            <div
              onClick={() => {
                navigate(`/request-buyer-id/${item.id}`);
                closeMenu();
              }}
              className="dropdown-item"
            >
              <IconButton
                className="icon"
                sx={{
                  bgcolor: Colors.bluishCyan,
                  "&:hover": { bgcolor: Colors.bluishCyan },
                }}
              >
                <RequestBuyerIdIcon />
              </IconButton>
              <span>Request Buyer ID</span>
            </div>
          )}
          {/* Delete Container Option */}
          {permissions?.["delete-container"] && (
            <div
              onClick={() => {
                if (
                  item?.client_costings === null ||
                  item?.client_costings === false
                ) {
                  setOpenDelete(true);
                  setShippingId(item?.shipping_id);
                } else {
                  ErrorToaster(
                    "Client Costing or Vendor Costing is already performed for this container"
                  );
                }
                closeMenu();
              }}
              className={`dropdown-item ${item?.client_costings ? "disabled" : ""
                }`}
            >
              <Delete sx={{ color: "red" }} className="icon" />
              <span>Delete Container</span>
            </div>
          )}

          {vendorApplyFund && (
            <Box
              onClick={() => {
                if (!item?.approval_pending) {
                  navigate(`/vendor-funds-apply`, {
                    state: item,
                  });
                } else {
                  ErrorToaster("Approve Pending Payments");
                }
              }}
              className="dropdown-item"
            >
              <IconButton
                sx={{
                  bgcolor: Colors.primary,
                  "&:hover": {
                    bgcolor: Colors.primary,
                  },
                }}
              >
                <AttachMoneyIcon sx={{ color: "#fff" }} />
              </IconButton>
              <Typography variant="body2">Apply Fund</Typography>
            </Box>
          )}
          {deleteReferri && (
            <div
              onClick={() => {
                onDeleteClick();
              }}
              className="dropdown-item"
            >
              <IconButton
                sx={{
                  bgcolor: Colors.danger,
                  "&:hover": {
                    bgcolor: Colors.danger,
                  },
                }}
                className="icon"
              >
                <Delete
                  sx={{
                    color: Colors.white,
                    height: "16px !important",
                  }}
                />
              </IconButton>
              <span>Delete</span>
            </div>
          )}

          {vendorDeleteIcon && (
            <div
              onClick={() => {
                onDeleteClick();
              }}
              className="dropdown-item"
            >
              <IconButton
                sx={{
                  bgcolor: Colors.danger,
                  "&:hover": {
                    bgcolor: Colors.danger,
                  },
                }}
                className="icon"
              >
                <Delete
                  sx={{
                    color: Colors.white,
                    height: "16px !important",
                  }}
                />
              </IconButton>
              <span>Delete</span>
            </div>
          )}

          {/* Recive Exit Paper */}
          {(receiveCec) &&
            <Box
              sx={{ flexDirection: "column" }}
            // className=""
            >
              {(permissions?.["reverse-cec"] || user.user_type == "A") && (
                <Tooltip
                  title={
                    (item?.exit_paper_received)
                      ? "Exit Paper Not Received!"
                      : parseFloat(item?.exit_paper_received)
                        ? "Shipping Paid. Can't Reverse!"
                        : ""
                  }
                  arrow
                  placement="top"
                >
                  <Box
                    component="div"
                    className="pdf-hide dropdown-item"
                    style={{
                      opacity:
                        parseFloat(item?.exit_paper_received)
                    }}
                    //  sx={{ display: "flex", justifyContent: "start !important" }}
                    onClick={onClickReverseEP}
                  >
                    <IconButton
                      sx={{
                        bgcolor:
                          parseFloat(item?.exit_paper_received)
                            ? Colors.grey
                            : Colors.bluishCyan,
                        "&:hover": {
                          bgcolor:
                            parseFloat(item?.exit_paper_received)
                              ? Colors.grey
                              : Colors.bluishCyan,
                        },
                      }}
                      disabled={
                        item?.exit_paper_received
                      }
                    >
                      <Edit
                        sx={{
                          color:
                            (
                              item?.exit_paper_received
                            )
                              ? Colors.grey
                              : Colors.white,
                          height: "16px !important",
                        }}
                      />
                    </IconButton>
                    <Typography
                      variant="body2"
                      sx={{
                        color:
                          (
                            item?.exit_paper_received
                          )
                            ? Colors.grey
                            : Colors.bluishCyan,

                      }}
                    >
                      Reverse EP
                    </Typography>
                  </Box>
                </Tooltip>
              )}


              {(permissions?.["reverse-cec"] || user.user_type == "A") && (
                <Tooltip
                  arrow
                  placement="top"
                >
                  <Box
                    component="div"
                    className="pdf-hide dropdown-item"
                    style={{

                      opacity:
                        daysRemaining === 0
                    }}
                    //  sx={{ display: "flex", justifyContent: "start !important" }}
                    onClick={onClickCustomCharge}
                  >
                    <IconButton
                      sx={{
                        bgcolor:
                          daysRemaining === 0
                            ? Colors.grey
                            : Colors.bluishCyan,
                        "&:hover": {
                          bgcolor:
                            daysRemaining !== 0
                              ? Colors.grey
                              : Colors.bluishCyan,
                        },
                      }}
                      disabled={
                        daysRemaining !== 0 || item?.exit_paper_received

                      }
                    >
                      <Edit
                        sx={{
                          color:
                            (
                              daysRemaining !== 0
                            )
                              ? Colors.grey
                              : Colors.white,
                          height: "16px !important",
                        }}
                      />
                    </IconButton>
                    <Typography
                      variant="body2"
                      sx={{
                        color:
                          (
                            daysRemaining !== 0
                          )
                            ? Colors.grey
                            : Colors.bluishCyan,

                      }}
                    >
                      Custom Charge
                    </Typography>
                  </Box>
                </Tooltip>
              )}

              {/* {(permissions?.["reverse-cec"] || user.user_type == "A") && (
                <Tooltip
                  title={
                    !(item?.exit_paper_received && item?.exit_paper_received_at)
                      ? "Exit Paper Not Received!"
                      : parseFloat(item?.shipping?.shipping_paid) >=
                        parseFloat(item?.shipping?.shipping_total)
                        ? "Shipping Paid. Can't Reverse!"
                        : ""
                  }
                  arrow
                  placement="top"
                >
                  <Box
                    component="div"
                    className="pdf-hide"
                    style={{
                      opacity:
                        !(
                          item?.exit_paper_received &&
                          item?.exit_paper_received_at
                        ) ||
                        parseFloat(item?.shipping?.shipping_paid) >=
                        parseFloat(item?.shipping?.shipping_total),
                    }}
                    sx={{ display: "flex", justifyContent: "start !important" }}
                    onClick={onClickReverseVCC}
                  >
                    <IconButton
                      sx={{
                        bgcolor:
                          !(
                            item?.exit_paper_received &&
                            item?.exit_paper_received_at
                          ) ||
                            parseFloat(item?.shipping?.shipping_paid) >=
                            parseFloat(item?.shipping?.shipping_total)
                            ? Colors.grey
                            : Colors.bluishCyan,
                        "&:hover": {
                          bgcolor:
                            !(
                              item?.exit_paper_received &&
                              item?.exit_paper_received_at
                            ) ||
                              parseFloat(item?.shipping?.shipping_paid) >=
                              parseFloat(item?.shipping?.shipping_total)
                              ? Colors.grey
                              : Colors.bluishCyan,
                        },
                      }}
                      disabled={
                        !(
                          item?.exit_paper_received &&
                          item?.exit_paper_received_at
                        ) ||
                        parseFloat(item?.shipping?.shipping_paid) >=
                        parseFloat(item?.shipping?.shipping_total)
                      }
                    >
                      <Edit
                        sx={{
                          color:
                            !(
                              item?.exit_paper_received &&
                              item?.exit_paper_received_at
                            ) ||
                              parseFloat(item?.shipping?.shipping_paid) >=
                              parseFloat(item?.shipping?.shipping_total)
                              ? Colors.grey
                              : Colors.white,
                          height: "16px !important",
                        }}
                      />
                    </IconButton>
                    <Typography
                      variant="body2"
                      sx={{
                        color:
                          !(
                            item?.exit_paper_received &&
                            item?.exit_paper_received_at
                          ) ||
                            parseFloat(item?.shipping?.shipping_paid) >=
                            parseFloat(item?.shipping?.shipping_total)
                            ? Colors.grey
                            : Colors.bluishCyan,
                      }}
                    >
                      Reverse
                    </Typography>
                  </Box>
                </Tooltip>
              )} */}



              {(permissions?.["delete-cec"] || user.user_type == "A") && (
                <Tooltip
                  title={
                    !(item?.exit_paper_received && item?.exit_paper_received_at)
                      ? "Exit Paper Not Received!"
                      : parseFloat(item?.shipping?.shipping_paid) >=
                        parseFloat(item?.shipping?.shipping_total)
                        ? "Shipping Paid. Can't Delete!"
                        : ""
                  }
                  arrow
                  placement="top"
                >
                  <Box
                    component="div"
                    className="pdf-hide dropdown-item"
                    style={{
                      opacity:
                        !(
                          item?.exit_paper_received &&
                          item?.exit_paper_received_at
                        ) ||
                          parseFloat(item?.shipping?.shipping_paid) >=
                          parseFloat(item?.shipping?.shipping_total)
                          ? 0.5
                          : 1,
                    }}
                    // sx={{ display: "flex", justifyContent: "start !important" }}
                    onClick={onClickDeleteVCC}
                  >
                    <IconButton
                      sx={{
                        bgcolor:
                          !(
                            item?.exit_paper_received &&
                            item?.exit_paper_received_at
                          ) ||
                            parseFloat(item?.shipping?.shipping_paid) >=
                            parseFloat(item?.shipping?.shipping_total)
                            ? Colors.grey
                            : Colors.error,
                        "&:hover": {
                          bgcolor:
                            !(
                              item?.exit_paper_received &&
                              item?.exit_paper_received_at
                            ) ||
                              parseFloat(item?.shipping?.shipping_paid) >=
                              parseFloat(item?.shipping?.shipping_total)
                              ? Colors.grey
                              : Colors.errorDark,
                        },
                      }}
                      disabled={
                        !(
                          item?.exit_paper_received &&
                          item?.exit_paper_received_at
                        ) ||
                        parseFloat(item?.shipping?.shipping_paid) >=
                        parseFloat(item?.shipping?.shipping_total)
                      }
                    >
                      <Delete
                        sx={{
                          color:
                            !(
                              item?.exit_paper_received &&
                              item?.exit_paper_received_at
                            ) ||
                              parseFloat(item?.shipping?.shipping_paid) >=
                              parseFloat(item?.shipping?.shipping_total)
                              ? Colors.grey
                              : Colors.red,
                          height: "20px !important",
                        }}
                      />
                    </IconButton>
                    <Typography
                      variant="body2"
                      sx={{
                        color:
                          !(
                            item?.exit_paper_received &&
                            item?.exit_paper_received_at
                          ) ||
                            parseFloat(item?.shipping?.shipping_paid) >=
                            parseFloat(item?.shipping?.shipping_total)
                            ? Colors.grey
                            : Colors.error,
                      }}
                    >
                      Delete
                    </Typography>
                  </Box>
                </Tooltip>
              )}
            </Box>
          }
          {(!receiveCec && salesCertificate) && <>
            {/* IssueMobaya */}
            {(permissions?.["reverse-mobaya"] || user?.user_type == "A") && (
              <Box
                onClick={onClickReverseIssueMobaya}
                sx={{
                  cursor: "pointer",
                }}
                className="dropdown-item"
              >
                <IconButton
                  sx={{
                    bgcolor: Colors.blackShade,
                    "&:hover": {
                      bgcolor: Colors.blackShade,
                    },
                  }}
                >
                  <Edit
                    sx={{
                      color: Colors.white,
                      height: "16px !important",
                    }}
                  />
                </IconButton>
                <Typography
                  variant="body2"
                  sx={{
                    color: Colors.blackShade,
                  }}
                >
                  Reverse
                </Typography>
              </Box>
            )}
            {(permissions?.["delete-mobaya"] || user?.user_type == "A") && (
              <Box
                component={"div"}
                onClick={onClickDeleteIssueMobaya}
                className="dropdown-item"
              >
                <IconButton
                  sx={{
                    bgcolor: Colors.danger,
                    "&:hover": {
                      bgcolor: Colors.danger,
                    },
                  }}
                >
                  <Delete
                    sx={{
                      color: Colors.white,
                      height: "16px !important",
                    }}
                  />
                </IconButton>
                <Typography
                  sx={{
                    color: Colors.danger,
                  }}
                >
                  Delete
                </Typography>
              </Box>
            )}
          </>}
        </div>
      )}

      <style jsx>{`
        .dropdown-container {
          position: relative;
          display: inline-block;
        }

        .dropdown-button {
          background-color: ${Colors.primary};
          border: none;
          cursor: pointer;
          color: ${Colors.white};
          border-radius: 100px;
          height: 30px;
          width: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .dropdown-menu {
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 100%;
          right: 0;
          background-color: #fff;
          border: 2px solid ${Colors.primary};
          border-radius: 5px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          z-index: 1000;
        }

        .dropdown-item {
          display: flex;
          align-items: center;
          padding: 8px 16px;
          cursor: pointer;
        }

        .dropdown-item:hover {
          background-color: #eff9e8;
        }

        .dropdown-item.disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }

        .icon {
          margin-right: 8px;
        }
      `}</style>
    </div>
  );
};

export default DropdownMenu;
