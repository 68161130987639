const routes = {
  getVinDetails: 'shippings/vinDetails',
  getShipping: 'shippings',
  createShipping: 'shippings/add',
  updateShippingVehicle: 'shippings/update',
  updateShipping: 'shippings/updateShip',
  deleteShipping: 'shippings/delete',
  getShippingVin: 'shippings/filters',
  getShippingInfo: 'shippings/fetchLatestUpdate', //W8- info-getting-Api
  getShippingTracking: 'shippings/tracking',
  getExportShippingTracking:'export/tracking',
  getContainerDetail: "shippings/details",
  updateContainer:"shippings/add",
  getBookingRefDetail:"vendors/planDetails"
};

export default routes