const routes = {
  getVehicleList: 'gatepass/vehicles',
  getGatePassVehicleApprovals: 'gatepass/vehicles/approvals',
  requestGatePass: 'gatepass/request',
  approveStatus: 'gatepass/approvalStatus',
  getGatePassDetails: 'gatepass/details',
  createGatePass: 'gatepass/create',
  deleteGatePass: 'gatepass/delete',
  payGatePass: 'gatepass/pay',
  payGatePassWithOutToken: 'gatepass/payWithOutToken',
  updateInOutStatus: 'gatepass/inOutStatus',
  getMobaya: 'gatepass/mobaya',
  issueMobaya: 'gatepass/issueMobaya',
  deleteMobaya: 'gatepass/deleteMobaya',
  updateRef: 'gatepass/updateReference',
  getGatePassList:'gatepass/internals',
  getGatePassPreview:'gatepass/preview',  
  createInternalGatepass:'gatepass/internal/create',  
  getInternalGatepass:'gatepass/internals',  
  updateInternalGP: 'gatepass/internal/update',
  getVinDetail: 'gatepass/search/vin',
  reverseEP: 'gatepass/reverse/mobaya/ep',
};

export default routes