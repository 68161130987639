import React, { useEffect, useState } from "react";
import {
    Grid,
    Box,
    Typography,
    Paper,
    Menu,
    MenuItem,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    tableCellClasses,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    CircularProgress,
    Tooltip

} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { PrimaryButton } from "components/Buttons";
import styled from "@emotion/styled";
import { makeStyles } from "@mui/styles";
import { FontFamily } from "assets";
import Colors from "assets/Style/Colors";
import Pagination from "components/Pagination";
import ClosingPeriodServices from "services/ClosingPeriod";
import { ErrorToaster, SuccessToaster } from "components/Toaster";
import moment from "moment";
import { CommaSeparator } from "utils";
import { useNavigate } from "react-router-dom";
const Row = styled(TableRow)(({ theme }) => ({
    border: 0,
}));
const Cell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 14,
        fontFamily: FontFamily.NunitoRegular,
        border: 0,
        textAlign: "center",
        whiteSpace: "nowrap",
        background: Colors.primary,
        color: Colors.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontFamily: FontFamily.NunitoRegular,
        textAlign: "center",
        textWrap: "nowrap",
        padding: "5px !important",

        ".MuiBox-root": {
            display: "flex",
            gap: "6px",
            alignItems: "center",
            justifyContent: "center",
            ".MuiBox-root": {
                cursor: "pointer",
            },
        },
        svg: {
            width: "auto",
            height: "24px",
        },
        ".MuiTypography-root": {
            textTransform: "capitalize",
            fontFamily: FontFamily.NunitoRegular,
            textWrap: "nowrap",
        },
        ".MuiButtonBase-root": {
            padding: "8px",
            width: "28px",
            height: "28px",
        },
    },
}));

const useStyles = makeStyles({
    loaderWrap: {
        display: "flex",
        height: 100,
        "& svg": {
            width: "40px !important",
            height: "40px !important",
        },
    },
    anchorLink: {
        textDecoration: "underline",
        color: Colors.twitter,
        cursor: "pointer",
    },
});
const ClosingPeriodsList = () => {
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const classes = useStyles();
    // *For Pagination
    const [totalCount, setTotalCount] = useState(0);
    const [pageLimit, setPageLimit] = useState(50);
    const [currentPage, setCurrentPage] = useState(1);
    const [auditDialogOpen, setAuditDialogOpen] = useState(false);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);


    useEffect(() => {
        getPeriods()
    }, [])
    const handleMenuOpen = (event, row) => {
        setAnchorEl(event.currentTarget);
        setSelectedRow(row);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        // setSelectedRow(null);
    };
    const handleEdit = () => {
        navigate(`/update-closing-period/${selectedRow.id}`)
        setAnchorEl(null);
        // setSelectedRow(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const columns = ["Closed Period", "Closed by", 'Status', "Closing Modules", "Temp Closing Date", "Perm. Closing Date", 'Closing Credit AED', 'Closing Debit AED', 'Opening Credit AED', 'Opening Debit AED', "Action"];

    const handleAuditTrailClose = () => {
        setAuditDialogOpen(false);
    };
    const handleAuditTrailOpen = () => {
        setAuditDialogOpen(true);
        handleMenuClose();
    };
    const getPeriods = async () => {
        try {
            setLoading(true);
            const res = await ClosingPeriodServices.getAllClosing();

            if (res?.data) {

                setRows(res?.data?.periods?.rows);
            } else {
                setRows([]);
            }
        } catch (error) {
            ErrorToaster(error);
        } finally {
            setLoading(false);
        }
    };

    const updateClosingPeriod = async () => {
        try {
            const res = await ClosingPeriodServices.UpdateClosePeriod()
            console.log('res is-------', res);
        } catch (error) {
            ErrorToaster(error)
        }
    }
    const handleTempCloseClick = () => {
        setConfirmDialogOpen(true);
        handleMenuClose();
    };
    const handleConfirmClose = async () => {
        try {
            setLoading(true);
            let obj = {
                period_id: selectedRow.id,
            };
            await ClosingPeriodServices.UpdateClosePeriod(obj);
            SuccessToaster("Closing period updated successfully");
            getPeriods(); // Refresh data
        } catch (error) {
            ErrorToaster(error);
        } finally {
            setConfirmDialogOpen(false);
            setLoading(false);
        }
    };
    return (
        <Box sx={{ m: 4, mb: 8 }}>
            {/* Audit Trail Dialog */}
            <Dialog open={auditDialogOpen} onClose={handleAuditTrailClose} maxWidth="md" fullWidth>
                <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>Audit Trail</DialogTitle>
                <DialogContent>
                    <TableContainer sx={{ borderRadius: 4 }}>
                        <Table>
                            <TableHead>
                                <TableRow sx={{ backgroundColor: Colors.primary, color: "white" }}>
                                    <Cell sx={{ color: "white", fontWeight: "bold" }}>Date</Cell>
                                    <Cell sx={{ color: "white", fontWeight: "bold" }}>Posted Entry</Cell>
                                    <Cell sx={{ color: "white", fontWeight: "bold" }}>Posted by</Cell>
                                    <Cell sx={{ color: "white", fontWeight: "bold" }}>Comment</Cell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedRow?.trail?.length > 0 ? (
                                    selectedRow?.trail?.map((item, index) => (
                                        <TableRow key={index}>
                                            <Cell>{item.created_at ? new Date(item.created_at).toLocaleDateString() : "-"}</Cell>
                                            <Cell
                                                className={classes.anchorLink + " " + "pdf-table"}
                                                onClick={() => {

                                                    //   window.open(
                                                    //     `/account-ledger/${vehicleAccountId}`,
                                                    //     {
                                                    //       state: {
                                                    //         accountName: item?.name,
                                                    //         nature: vehicleAccountNature,
                                                    //       },
                                                    //     }
                                                    //   )
                                                }}
                                                sx={{ color: "#0A74DA", fontWeight: "bold" }}>
                                                {item.entry?.series_id ? `${item.entry.series_id}${item.entry.id}` : "-"}
                                            </Cell>
                                            <Cell>{item.entry?.createdBy?.name ?? "Unknown"}</Cell>
                                            <Cell>{item.entry?.description ?? "No Comments"}</Cell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <Cell colSpan={4} sx={{ textAlign: "center", fontWeight: "bold" }}>
                                            No Audit Data Available
                                        </Cell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <PrimaryButton onClick={handleAuditTrailClose} title="Close" />
                </DialogActions>
            </Dialog>

            <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
                <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>
                    Confirm Temporary Close
                </DialogTitle>
                <DialogContent>
                    <Typography sx={{ textAlign: "center" }}>
                        Are you sure you want to Permanent close this period?
                    </Typography>
                </DialogContent>
                <Grid item xs={12} sm={12} sx={{ mb: 2, textAlign: "center" }}>
          <PrimaryButton
            title="No"
            type="submit"
            buttonStyle={{ minWidth: "120px" }}
            onClick={() => { setConfirmDialogOpen(false) }}

          />
          <Box component={"span"} sx={{ mx: 1 }} />
          <PrimaryButton
            title="Yes"
            type="button"
            buttonStyle={{
              minWidth: "120px",
              bgcolor: Colors.danger,
              "&:hover": { bgcolor: Colors.danger },
            }}
            bgcolor={Colors?.danger}
            onClick={() => { handleConfirmClose() }}
          />
        </Grid>
                
            </Dialog>
            <Typography variant="h4" sx={{ mb: 2, color: "#094526" }}>
                Closing Periods List
            </Typography>

            <TableContainer
                component={Paper}
                sx={{
                    boxShadow: "0px 8px 18px 0px #9B9B9B1A",
                    borderRadius: 2,
                    height: rows.length < 2 ? "calc(100vh - 630px)" : "calc(100vh - 330px)",
                }}
            >
                <Table>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: Colors.primary }}>
                            {columns.map((col, index) => (
                                <Cell key={index} sx={{ color: "white", fontWeight: "bold" }}>
                                    {col}
                                </Cell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <Cell colSpan={columns.length} align="center">
                                    <CircularProgress />
                                </Cell>
                            </TableRow>
                        ) : rows.length > 0 ? (
                            rows.map((row) => {
                                const issuedNames = [...new Set(row?.accounts
                                    .map((q) => q?.account?.name)
                                    .filter(Boolean))]
                                    .join(", ");
                                return (
                                    <TableRow key={row.id} sx={{ backgroundColor: row.id % 2 === 0 ? "#effcee" : "white" }}>
                                        <Cell>{`${moment(row?.end_date).format('DD-MM-YYYY')}`}</Cell>
                                        <Cell>{row.createdBy?.name}</Cell>
                                        <Cell>
                                            {row?.status
                                                ? row.status.charAt(0).toUpperCase() + row.status.slice(1).toLowerCase()
                                                : "-"}
                                        </Cell>

                                        <Cell><Tooltip
                                            className="pdf-hide"
                                            title={issuedNames}
                                            arrow
                                            placement="top"
                                            slotProps={{
                                                popper: {
                                                    modifiers: [
                                                        {
                                                            name: "offset",
                                                            options: {
                                                                offset: [10, -2],
                                                            },
                                                        },
                                                    ],
                                                },
                                            }}
                                        >
                                            {issuedNames.length > 17 ? issuedNames?.slice(0, 8) + "..." : issuedNames}
                                        </Tooltip>
                                            <Box component={"div"} sx={{ display: "none !important", whiteSpace: 'pre-wrap !important' }}
                                                className="pdf-show pdf-text-wrap"
                                            >
                                                {issuedNames}
                                            </Box></Cell>
                                        <Cell>{moment(row.created_at).format('DD-MM-YYYY')}</Cell>
                                        <Cell>{row?.closed_at ? moment(row?.closed_at).format('DD-MM-YYYY') : '-'}</Cell>
                                        <Cell>{CommaSeparator(parseFloat(row?.closing_credit).toFixed(2))}</Cell>
                                        <Cell>{CommaSeparator(parseFloat(row?.closing_debit).toFixed(2))}</Cell>
                                        <Cell>{CommaSeparator(parseFloat(row?.opening_credit).toFixed(2))}</Cell>
                                        <Cell>{CommaSeparator(parseFloat(row?.opening_debit).toFixed(2))}</Cell>
                                        <Cell>
                                            <IconButton onClick={(event) => handleMenuOpen(event, row)}>
                                                <MoreVertIcon />
                                            </IconButton>
                                        </Cell>
                                    </TableRow>
                                )
                            })
                        ) : (
                            <TableRow>
                                <Cell colSpan={columns.length} align="center">
                                    No data available
                                </Cell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* ========== Pagination ========== */}
            <Pagination
                currentPage={currentPage}
                pageSize={pageLimit}
                onPageSizeChange={(size) => {
                    // getInvoiceList(1, size.target.value)
                }}
                tableCount={rows?.length}
                totalCount={totalCount}
                onPageChange={(page) => {
                    // getInvoiceList(page, "")
                }}
            />
            {/* Action Menu */}
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                {/* <MenuItem onClick={handleMenuClose}>View</MenuItem> */}
                <MenuItem onClick={handleEdit}>Edit Temp. Closing</MenuItem>
                <MenuItem onClick={handleTempCloseClick} disabled={selectedRow?.status === "closed" || selectedRow?.status === "draft" }>Permanent Close</MenuItem>
                <MenuItem onClick={handleAuditTrailOpen} disabled={selectedRow?.status === "closed" || selectedRow?.status === "draft"}>
                    Audit Trail
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default ClosingPeriodsList;
