const routes = {
  getUnits: 'export/accounts/units',
  getMajorCategories: 'export/accounts/majorCategories',
  getSubCategories: 'export/accounts/subCategories',
  getAccountCode: 'export/accounts/code',
  getAccountBySubCategory: 'export/accounts/bySubCategory',
  createAccount: 'export/accounts/add',
  updateAccount: 'export/accounts/update',
  getAccounts: 'export/accounts',
  getAccountsDropDown: 'export/accounts/dropdown',
  getAccountsApprovals: 'export/accounts/approvals',
  approveAccount: 'export/accounts/approve',
  getChartOfAccount: 'export/accounts/coa',
  getChartOfAccountSubAccount: 'export/accounts/subAccounts',
  getAccountLedgers: 'export/accounts/ledger',
  getGeneralJournalLedgers: 'export/accounts/generaljournal',
  getVaultCustomers: 'export/accounts/vault/customers',
  getPaymentAccounts: 'export/accounts/vault/paymentAccounts',
  getVaultTopUps: 'export/accounts/vault/topups',
  createVaultTopUp: 'export/accounts/vault/topup',
  getVaultTopUpDetail: 'export/accounts/vault/topups/details',
  getVaultTopUpPreview: 'export/accounts/vault/topups/preview',
  getAccountReports: 'export/accounts/reports',
  getCashierActivity: 'export/accounts/cashierActivity',
  PaymentVoucher: 'export/accounts/paymentVouchers/add',
  getVouchers: 'export/accounts/paymentVouchers',
  getVoucherDetails: 'export/accounts/paymentVouchers/details',
  getVoucherPreview: 'export/accounts/paymentVouchers/preview',
  ReceiptVoucher: 'export/accounts/receiptVouchers/add',
  getReceipts: 'export/accounts/receiptVouchers',
  getReceiptDetail: 'export/accounts/receiptVouchers/details',
  getReceiptPreview: 'export/accounts/receiptVouchers/preview',
  createJournalVoucher: 'export/accounts/journalVouchers/add',
  updateJournalVoucher: 'export/accounts/journalVouchers/update',
  getJournalVouchers: 'export/accounts/journalVouchers',
  getJournalVoucherDetail: 'export/accounts/journalVouchers/details',
  getJournalVoucherPreview: 'export/accounts/journalVouchers/preview',
  createFundTransferVoucher: 'export/accounts/iftVouchers/add',
  getFundTransferVouchers: 'export/accounts/iftVouchers/',
  getFundTransferVoucherDetail: 'export/accounts/iftVouchers/details',
  getFundTransferVoucherPreview: 'export/accounts/iftVouchers/preview',
  getVehicleSumLedger: 'export/accounts/ledgerReports',
  getShippingProfit: 'export/clients/shippingProfit',
  getShippingReceiving: 'export/accounts/shippingReceiving',
  getShippingAging: 'export/accounts/shippingReceivableAging',
  getFundTransferApproval: 'export/accounts/iftVouchers/approvals',
  FundTransferApprove: 'export/accounts/iftVouchers/approve',
  getBanks: 'export/system/banks',
  createBank: 'export/system/banks/add',
  updateBank: 'export/system/banks/update',
  getExportCosting: 'export/costing',
  updateCosting: 'export/costing/update',
  updateAgentCosting: 'export/costing/agent/update',
  updateCountryCosting: 'export/costing/country/update',
  getShippingAging: 'export/accounts/aging',
  getCostProfit: 'export/costing/country',
  getUnpaidInvoices: 'export/vehicles',
  getContainerPayments: 'export/containers',
  reversePaymentVoucher: 'export/accounts/paymentVouchers/reverse',
  reverseReceiptVoucher: 'export/accounts/receiptVouchers/reverse',
  reverseJV:'accounts/journalVouchers/reverse',
};

export default routes
