const routes = {
  getVehicleBookingFilter: "vehicleBookings/filters",
  getVehicleBookings: "vehicleBookings",
  getVehicleBookingDetail: "vehicleBookings/details",
  createVehicleBooking: "vehicleBookings/add",
  bulkVehicleBooking: "vehicleBookings/addBulk",
  updateVehicleBooking: "vehicleBookings/edit",
  getMissingFields: "vehicleBookings/missingValues",
  updateMissingFieldVehicleBooking: "vehicleBookings/update",
  getApprovalList: "vehicleBookings/approvals",
  getApprovalDetail: "vehicleBookings/approvals/details",
  reviewApproval: "vehicleBookings/approvals/review",
  getBookingCustomers: "vehicleBookings/filters",
  createManualInvoice: "vehicleBookings/pseudo/invoice",
  getManualInvoices: "vehicleBookings/pseudo/invoice",
  getShippingVins: "vehicleBookings/pseudo/shipping/vin",
  updateVehicleImages: "vehicleBookings/bulk/upload",
  updatePicture: "vehicleBookings/update/pictures",
};

export default routes;
