import React, { useRef, useEffect } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Edit, Delete } from '@mui/icons-material';
import MessageIcon from '@mui/icons-material/Message';
import { CashIcon, CreateIcon, EyeIcon, RequestBuyerIdIcon } from 'assets';
import { Box, IconButton, Typography } from '@mui/material';
import WhatsApp from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import { Link } from 'react-router-dom';
import { FiDownload } from 'react-icons/fi';
import { Icons } from "assets/index";
import DeleteIcon from '@mui/icons-material/Delete';

const ExportDropDownMenu = ({
    item,
    permissions,
    navigate,
    setOpenDelete,
    setShippingId,
    ErrorToaster,
    Colors,
    isOpen,
    onToggle,
    onClick1,
    onClick2,
    onAllocateClick,
    onMessageClick,
    id,
    isDelete,
    onDeleteClick,
    isEdit,
    viewInvoice,
    invoice,
    deleteInvoice,
    whatsAppClick,
    emailClick,
    isForward,
    onEdit,
    printInvoiceClick,
    printFormClick,
    handleUpdate,
    handleViewDetail,
    vehicle,
    handleDelete,
    container,
    packingListView,
    border
}) => {
    const dropdownRef = useRef(null);

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                onToggle(false); // Close the dropdown
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onToggle]);

    const closeMenu = () => onToggle(false);
    return (
        <div ref={dropdownRef} className="dropdown-container">
            <div onClick={() => onToggle(!isOpen)} style={{ backgroundColor: Colors.primary }} className="dropdown-button">
                <MoreVertIcon />
            </div>

            {isOpen && (
                <div className="dropdown-menu">
                    
                    {container &&
                        <div
                            className="dropdown-item"
                            onClick={() => {
                                onEdit()
                                closeMenu()
                            }}
                        >
                            <IconButton
                                sx={{
                                    bgcolor: item?.paid_amount <= 0 ? Colors.blackShade : Colors.lightGrey,
                                    "&:hover": {
                                        bgcolor: item?.paid_amount <= 0 ? Colors.blackShade : Colors.lightGrey,
                                    },
                                }}
                                className='icon'
                            >
                                <Edit
                                    sx={{
                                        color: Colors.white,
                                        height: "16px !important",
                                    }}
                                />
                            </IconButton>
                            <Typography variant="body2">Edit</Typography>
                        </div>
                    }
                    {permissions && (
                        <div
                            className="dropdown-item"
                            onClick={() => {
                                handleViewDetail()
                                closeMenu()
                            }}
                        >
                            <IconButton
                                className='icon'
                                sx={{
                                    bgcolor: Colors.primary,
                                    "&:hover": {
                                        bgcolor: Colors.primary,
                                    },
                                }}
                            >
                                <EyeIcon />
                            </IconButton>
                            <Typography variant="body2">View Detail</Typography>
                        </div>
                    )}
                    {permissions && !container && !border&& (
                        <div
                            className="dropdown-item"
                            onClick={() => {
                                printInvoiceClick()
                                closeMenu()
                            }}
                        >
                            <IconButton
                                className='icon'
                                sx={{
                                    bgcolor: Colors.invoice,
                                    "&:hover": {
                                        bgcolor: Colors.invoice,
                                    },
                                }}
                            >
                                <img src={Icons.invoiceIcon} alt="" width={"12px"} />
                            </IconButton>
                            <Typography variant="body2">Print Invoice</Typography>
                        </div>
                    )}
                    {permissions && vehicle && (
                        <div
                            className="dropdown-item"
                            onClick={() => {
                                printFormClick()
                                closeMenu()
                            }

                            }
                        >
                            <IconButton
                                className='icon'
                                sx={{
                                    bgcolor: Colors.blue,
                                    "&:hover": {
                                        bgcolor: Colors.blue,
                                    },
                                }}
                            >
                                <img src={Icons.invoiceIcon} alt="" width={"12px"} />
                            </IconButton>
                            <Typography variant="body2">Print Form</Typography>
                        </div>
                    )}
                    {permissions && vehicle && (
                        <div
                            className="dropdown-item"
                            onClick={() => {
                                handleUpdate()
                                closeMenu()
                            }}
                            sx={{
                                opacity: item?.status?.name === 'Handover' ? 0.5 : 1,
                                cursor: item?.status?.name === 'Handover' ? 'not-allowed' : 'pointer',
                            }}
                        >
                            <IconButton
                                className='icon'
                                sx={{
                                    bgcolor: Colors.blackShade,
                                    "&:hover": {
                                        bgcolor: item?.status?.name === 'Handover' ? Colors.blackShade : Colors.blackShade,
                                    },
                                }}
                                disabled={item?.status?.name === 'Handover'}
                            >
                                <Edit
                                    sx={{
                                        color: Colors.black,
                                        height: "16px !important",
                                    }}
                                />
                            </IconButton>
                            <Typography
                                variant="body2"
                                sx={{
                                    color: "",
                                    cursor: 'pointer',
                                    // color: item?.status?.name === 'Handover' ? Colors.grey : Colors.black,
                                    // cursor: item?.status?.name === 'Handover' ? 'not-allowed' : 'pointer',
                                }}
                            >
                                Update
                            </Typography>
                        </div>
                    )}
                    {/* WhatsApp Option */}
                    {isForward &&
                        <>
                            <div className="dropdown-item">
                                <Box component={"div"} onClick={() => {
                                    whatsAppClick(id)
                                    closeMenu();
                                }} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <IconButton
                                        sx={{ bgcolor: Colors.primary, '&:hover': { bgcolor: Colors.primary }, p: '8px' }}

                                    >
                                        <WhatsApp sx={{ fontSize: '18px', color: '#FFFFFF' }} />
                                    </IconButton>
                                    <Typography variant="body2" sx={{ fontSize: '12px' }}>
                                        WhatsApp
                                    </Typography>
                                </Box>
                            </div>

                            {/* Email Option */}
                            <div className="dropdown-item">
                                <Box component={"div"} onClick={() => {
                                    emailClick()
                                    closeMenu();
                                }} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <IconButton
                                        sx={{ p: '8px' }}

                                    >
                                        <EmailIcon sx={{ fontSize: '18px', color: Colors.blue }} />
                                    </IconButton>
                                    <Typography variant="body2" sx={{ fontSize: '12px' }}>
                                        Email
                                    </Typography>
                                </Box>
                            </div>
                        </>}
                    {isDelete && <div onClick={() => {
                        handleDelete()
                        closeMenu()
                    }} className="dropdown-item">
                        <IconButton
                            sx={{ p: '8px' }}

                        >
                            <DeleteIcon
                                sx={{
                                    fontSize: '18px',
                                    color: Colors.danger,
                                }}
                            />
                        </IconButton>
                        <Typography
                            variant='body2'
                            sx={{ fontSize: '12px' }}
                        >Delete</Typography>
                    </div>}
                    {/* Container list Option */}
                    {
                        container && (
                            <>
                                <div
                                    className="dropdown-item"
                                    onClick={() => {
                                       handleUpdate()
                                       closeMenu()
                                    }}
                                    sx={{
                                        pointerEvents: item?.containerPacking ? "auto" : "none",
                                    }}
                                >
                                    <IconButton
                                    className='icon'
                                        sx={{
                                            bgcolor: item?.containerPacking ? Colors.primary : "grey",
                                            "&:hover": {
                                                bgcolor: item?.containerPacking ? Colors.primary : "grey",
                                            },
                                        }}
                                    >
                                        <Edit
                                            sx={{
                                                color: Colors.white,
                                                height: "16px !important",
                                            }}
                                        />
                                    </IconButton>
                                    <Typography variant="body2">Update Packing List</Typography>
                                </div>
                                <div
                                    className="dropdown-item"
                                    onClick={() => {
                                        packingListView()
                                        closeMenu()
                                    }}
                                    sx={{
                                        pointerEvents: item?.containerPacking ? "auto" : "none",
                                    }}
                                >
                                    <IconButton
                                    className='icon'
                                        sx={{
                                            bgcolor: item?.containerPacking ? Colors.primary : "grey",
                                            "&:hover": {
                                                bgcolor: item?.containerPacking ? Colors.primary : "grey",
                                            },
                                        }}
                                    >
                                        <EyeIcon />
                                    </IconButton>
                                    <Typography variant="body2">View Packing List</Typography>
                                </div>
                            </>
                        )
                    }
                    {/* Border Costing Option */}

                    {border && (
                        <div
                            className="dropdown-item"
                            onClick={() => {
                                handleUpdate()
                                closeMenu()
                            }}
                            sx={{
                                opacity: item?.status?.name === 'Handover' ? 0.5 : 1,
                                cursor: item?.status?.name === 'Handover' ? 'not-allowed' : 'pointer',
                            }}
                        >
                            <IconButton
                                className='icon'
                                sx={{
                                    bgcolor: Colors.blackShade,
                                    "&:hover": {
                                        bgcolor: item?.status?.name === 'Handover' ? Colors.blackShade : Colors.blackShade,
                                    },
                                }}
                                disabled={item?.status?.name === 'Handover'}
                            >
                                <Edit
                                    sx={{
                                        color: item?.status?.name === 'Handover' ? Colors.grey : Colors.white,
                                        height: "16px !important",
                                    }}
                                />
                            </IconButton>
                            <Typography
                                variant="body2"
                                sx={{
                                    color: item?.status?.name === 'Handover' ? Colors.grey : Colors.black,
                                    cursor: item?.status?.name === 'Handover' ? 'not-allowed' : 'pointer',
                                }}
                            >
                                Update
                            </Typography>
                        </div>
                    )}
                    {/* <Link
              className="icon"
              target="_blank"
              to={
                process.env.REACT_APP_IMAGE_BASE_URL + item.documents[0]
              }
              style={{textAlign:"center", }}
            >
              <FiDownload color={Colors.primary} />
            </Link> */}

                </div>
            )}

            <style jsx>{`
                .dropdown-container {
                    position: relative;
                    display: inline-block;
                }

                .dropdown-button {
                    background-color: ${Colors.primary};
                    border: none;
                    cursor: pointer;
                    color: ${Colors.white};
                    border-radius: 100px;
                    height: 30px;
                    width: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .dropdown-menu {
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    top: 100%;
                    right: 0;
                    background-color: #fff;
                    border: 2px solid ${Colors.primary};
                    border-radius: 5px;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                    z-index: 1000;
                }

                .dropdown-item {
                    display: flex;
                    align-items: center;
                    padding: 8px 16px;
                    cursor: pointer;
                }

                .dropdown-item:hover {
                    background-color: #eff9e8};
                }

                .dropdown-item.disabled {
                    cursor: not-allowed;
                    opacity: 0.5;
                }

                .icon {
                    margin-right: 8px;
                }
            `}</style>
        </div>
    );
};

export default ExportDropDownMenu;
