import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  tableCellClasses
} from "@mui/material";
import { useForm } from "react-hook-form";
import { Add, CancelOutlined, Delete, ExpandLess, ExpandMore } from "@mui/icons-material";
import SelectField from "components/Select";
import { PrimaryButton } from "components/Buttons";
import InputField from "components/Input";
import ShippingServices from "services/Shipping";
import { ErrorToaster, SuccessToaster } from "components/Toaster";
import SystemServices from "services/System";
import { getYearMonthDateFormate } from "utils";
import { useNavigate } from "react-router-dom";
import DatePicker from "components/DatePicker";
import Colors from "assets/Style/Colors";
import { FontFamily } from "assets";
import styled from "@emotion/styled";

const Row = styled(TableRow)(({ theme }) => ({
  border: 0,
}));

const Cell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 14,
    fontFamily: FontFamily.NunitoRegular,
    border: 0,
    padding: "15px",
    textAlign: "center",
    whiteSpace: "nowrap",
    background: Colors.primary,
    color: Colors.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: FontFamily.NunitoRegular,
    textAlign: "center",
    textWrap: "nowrap",
    padding: "5px !important",

    ".MuiBox-root": {
      display: "flex",
      gap: "6px",
      alignItems: "center",
      justifyContent: "center",
      ".MuiBox-root": {
        cursor: "pointer",
      },
    },
    svg: {
      width: "auto",
      height: "24px",
    },
    ".MuiTypography-root": {
      textTransform: "capitalize",
      fontFamily: FontFamily.NunitoRegular,
      textWrap: "nowrap",
    },
    ".MuiButtonBase-root": {
      padding: "8px",
      width: "28px",
      height: "28px",
    },
  },
}));
function CreateShipping() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues
  } = useForm();
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [vinLoading, setVinLoading] = useState(false);
  const [shippingVins, setShippingVins] = useState([]);
  const [selectedShippingVin, setSelectedShippingVin] = useState(null);
  const [bookingRef, setBookingRef] = useState("");
  const [vehicleDetail, setVehicleDetail] = useState([]);
  const [tableExpanded, setTableExpanded] = useState(true);
  // *For Shipping Line
  const [shippingLines, setShippingLines] = useState([]);
  const [selectedShippingLine, setSelectedShippingLine] = useState(null);
  // *For Container Sizes
  const [containerSizes, setContainerSizes] = useState([]);
  const [selectedContainerSize, setSelectedContainerSize] = useState(null);
  // *For Shipping Vendors
  const [shippingVendors, setShippingVendors] = useState([]);
  const [selectedShippingVendor, setSelectedShippingVendor] = useState(null);

  // *For Shipping Yards
  const [galaxyYards, setGalaxyYards] = useState([]);
  const [selectedGalaxyYard, setSelectedGalaxyYard] = useState(null);



  // *For Clearers
  const [clearers, setClearers] = useState([]);
  const [selectedClearer, setSelectedClearer] = useState(null);

  // *For Vehicle Towers
  const [vehicleTowers, setVehicleTowers] = useState([]);
  const [selectedVehicleTower, setSelectedVehicleTower] = useState(null);



  // *For Destination
  const [destinations, setDestinations] = useState([]);
  const [selectedDestination, setSelectedDestination] = useState(null);

  // *For Loading Port
  const [loadingPort, setLoadingPort] = useState([]);
  const [selectedLoadingPort, setSelectedLoadingPort] = useState(null);



  // *For Handle Date
  const [loadingDate, setLoadingDate] = useState();
  const [exportDate, setExportDate] = useState();
  const [etaDate, setEtaDate] = useState();
  const [arrivedDate, setArrivedDate] = useState();
  const [arrivedGalaxyDate, setArrivedGalaxyDate] = useState();
  const [auctionDate, setAuctionDate] = useState();

  // *For Countries
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);

  // *For Business Location
  const [businessLocation, setBusinessLocation] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [disableVin, setDisableVin] = useState(false);
  const [disableBookingRef, setDisableBookingRef] = useState(false);
  const [planDetail, setPlanDetail] = useState(null)
  const getShippingVin = async () => {
    try {
      const { data } = await ShippingServices.getShippingVin({ unused: true });
      setShippingVins(
        data?.filters?.vins.map((item) => ({ id: item, name: item }))
      );
    } catch (error) {
      ErrorToaster(error);
    }
  };

  // const getVinDetails = async () => {
  //   setVinLoading(true);
  //   try {
  //     const { data } = await ShippingServices.getVinDetails({ vin: selectedShippingVin?.id });
  //     if (data?.booking?.invoice?.balance === "0.000") {
  //       setVehicleDetail([...vehicleDetail, data?.booking]);
  //     } else {
  //       ErrorToaster("Please pay for the vehicle first");
  //     }
  //   } catch (error) {
  //     ErrorToaster(error);
  //   } finally {
  //     setVinLoading(false);
  //   }
  // };

  const getBookingDetails = async (refNo) => {
    try {
      let params = {
        booking_ref_no: getValues('bookingRef')
      }
      const { data } = await ShippingServices.getBookingRefDetail(params)
      if (data?.plans?.rows?.length) {
        const plan = data.plans.rows[0];
        setVehicleDetail(
          plan.vehicles.map((vehicle) => ({
            vin: vehicle.vin,
            buyer_id: vehicle.customer_id,
            customer_id: vehicle.customer?.id,
            customer_name: vehicle.customer?.name,
            lot_number: vehicle.lot_number,
            country_name: vehicle.loading_port,
            location: vehicle.source,
            booking_id: vehicle?.booking_id,
            location: vehicle?.location,
            country: vehicle?.location
          }))
        );
        setPlanDetail(plan)
        getDestinations(null, plan.destination_id)
        getLoadingPorts(plan.loading_port_id)
        getShippingLines(null, plan?.shipping_line_id)
        getShippingVendors(null, plan?.service_provided_id)
        setValue('')
        setValue("containerNo", plan.container_no);
        setValue("destination", plan.destination_id);
        setValue("loadingPort", plan.loading_port_id);
        setValue("shippingVendor", plan.service_provided_id);
        setValue("shippingLine", plan.shipping_line_id);
        setValue("towing_vendor", plan.suggested_towing_vendor);
        setValue("note", plan.note);
        setValue('bookingNo', plan?.booking_ref_no)
        setDisableVin(true)
      } else {
        ErrorToaster("No booking details found.");
      }

    } catch (error) {
      ErrorToaster(error);
    }
  };
  const saveContainer = async () => {
    setLoading(true);
    try {
      SuccessToaster("Container saved successfully");
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getShippingVin();
    getContainerSizes();

    getBusinessCountries();
    getShippingLines();
    getShippingVendors();
    getGalaxyYards();
    getClearers();
    getVehicleTowers();
    getDestinations();
    getLoadingPorts();
  }, []);
  const getContainerSizes = async (search) => {
    try {
      let params = {
        page: 1,
        limit: 50,
        search: search,
      };
      const { data } = await SystemServices.getContainerSizes(params);
      setContainerSizes(data?.cont?.rows);
    } catch (error) {
      ErrorToaster(error);
    }
  };
  const handleLoadingDate = (newDate) => {
    try {
      // eslint-disable-next-line eqeqeq
      if (newDate == "Invalid Date") {
        setLoadingDate("invalid");
        return;
      }
      setLoadingDate(newDate);
      setValue("loadingDate", newDate);
    } catch (error) {
      ErrorToaster(error);
    }
  };
  const handleExportDate = (newDate) => {
    try {
      // eslint-disable-next-line eqeqeq
      if (newDate == "Invalid Date") {
        setExportDate("invalid");
        return;
      }
      setExportDate(newDate);
      setValue("exportDate", newDate);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const handleEtaDate = (newDate) => {
    try {
      // eslint-disable-next-line eqeqeq
      if (newDate == "Invalid Date") {
        setEtaDate("invalid");
        return;
      }
      setEtaDate(newDate);
      setValue("etaDate", newDate);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const handleArrivedDate = (newDate) => {
    try {
      // eslint-disable-next-line eqeqeq
      if (newDate == "Invalid Date") {
        setArrivedDate("invalid");
        return;
      }
      setArrivedDate(newDate);
      setValue("arrivedDate", newDate);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const handleArrivedGalaxyDate = (newDate) => {
    try {
      // eslint-disable-next-line eqeqeq
      if (newDate == "Invalid Date") {
        setArrivedGalaxyDate("invalid");
        return;
      }
      setArrivedGalaxyDate(newDate);
      setValue("arrivedGalaxyDate", newDate);
    } catch (error) {
      ErrorToaster(error);
    }
  };
  const getBusinessCountries = async () => {
    try {
      const { data } = await SystemServices.getBusinessCountries();
      setCountries(data?.countries);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  // *For Get BUsiness Location
  const getBusinessLocation = async (countryId) => {
    try {
      const { data } = await SystemServices.getBusinessLocation(countryId);
      const formattedData = [];
      data?.locations?.forEach((e) => {
        let obj = {
          id: e.id,
          name: e?.state_code + "-" + e?.city_name,
        };
        formattedData.push(obj);
      });
      setBusinessLocation(formattedData);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  // *For Get Shipping Lines
  const getShippingLines = async (search, defaultId) => {
    try {
      let params = {
        page: 1,
        limit: 50,
        search: search,
      };
      const { data } = await SystemServices.getShippingLines(params);
      if (defaultId) {
        setSelectedShippingLine(data?.lines?.rows.find(e => e?.id === defaultId))
      }
      setShippingLines(data?.lines?.rows);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  // *For Get Shipping Lines
  const getShippingVendors = async (search, defaultId) => {
    try {
      let params = {
        page: 1,
        limit: 50,
        search: search,
      };
      const { data } = await SystemServices.getShippingVendors(params);
      if (defaultId) {
        setSelectedShippingVendor(data?.vendors?.rows.find(e => e?.id === defaultId))
      }
      setShippingVendors(data?.vendors?.rows);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  // *For Get Galaxy Yards
  const getGalaxyYards = async (search) => {
    try {
      let params = {
        page: 1,
        limit: 50,
        search: search,
      };
      const { data } = await SystemServices.getGalaxyYards(params);
      setGalaxyYards(data?.yards?.rows);
    } catch (error) {
      ErrorToaster(error);
    }
  };


  // *For Create Galaxy Yards
  const createGalaxyYard = async (name) => {
    try {
      let obj = {
        name: name,
      };
      const { data } = await SystemServices.createGalaxyYard(obj);
      getGalaxyYards();
      setSelectedGalaxyYard(data?.model);
      setValue("galaxyYard", data?.model?.name);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  // *For Get Clearers
  const getClearers = async (search) => {
    try {
      let params = {
        page: 1,
        limit: 50,
        search: search,
      };
      const { data } = await SystemServices.getClearers(params);
      setClearers(data?.clearers?.rows);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  // *For Get Vehicle Towers
  const getVehicleTowers = async (search) => {
    try {
      let params = {
        page: 1,
        limit: 50,
        search: search,
      };
      const { data } = await SystemServices.getVehicleTowers(params);
      setVehicleTowers(data?.towing?.rows);
    } catch (error) {
      ErrorToaster(error);
    }
  };
  const getDestinations = async (search, defaultId) => {
    try {
      let params = {
        page: 1,
        limit: 50,
        search: search,
      };
      const { data } = await SystemServices.getDestinations(params);
      if (defaultId) {
        setSelectedDestination(data?.destinations?.rows.find(e => e?.id === defaultId))
      }
      setDestinations(data?.destinations?.rows);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  // *For Get Loading Port
  const getLoadingPorts = async (defaultId) => {
    try {
      const { data } = await SystemServices.getLoadingPorts();
      if (defaultId) {
        setSelectedLoadingPort(data?.ports.find(e => e?.id === defaultId))
      }
      setLoadingPort(data?.ports);
    } catch (error) {
      ErrorToaster(error);
    }
  };
  // *For Get Vin Details
  const getVinDetails = async () => {
    setVinLoading(true);
    try {
      let params = {
        vin: getValues('shippingVin'),
      };
      const { data } = await ShippingServices.getVinDetails(params);
      if (data?.booking?.is_relist) {
        return ErrorToaster("Relist Vehicle can not be added");
      }
      if (data?.booking?.invoice?.balance === "0.000") {
        const vehicles = [...vehicleDetail];
        const index = vehicleDetail.findIndex(
          (e) => e?.id === data?.booking?.id
        );
        if (index === -1) {
          vehicles.push(data?.booking);
          console.log(vehicles, "data");
          let selectedData = vehicles[vehicles.length - 1]?.location;
          setSelectedCountry({
            id: selectedData?.country_id,
            name: selectedData?.country_name,
          });
          getBusinessLocation(selectedData?.country_id);
          setValue("country", {
            id: selectedData?.country_id,
            name: selectedData?.country_name,
          });
          setSelectedLocation({
            id: selectedData?.id,
            name: selectedData?.state_code + "-" + selectedData?.city_name,
          });
          setValue("location", {
            id: selectedData?.id,
            name: selectedData?.state_code + "-" + selectedData?.city_name,
          });

          setVehicleDetail(vehicles);
          setDisableBookingRef(true)
          // reset2();
        } else {
          ErrorToaster("Vehicle already added.");
        }
      } else {
        ErrorToaster("Please Pay Vehicle first");
      }
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setVinLoading(false);
    }
  };

  // *For Remove Vehicle VIN Detail
  const removeVehicleDetail = (index) => {
    try {
      let newVehicleDetail = [...vehicleDetail];
      newVehicleDetail.splice(index, 1);
      setVehicleDetail(newVehicleDetail);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  // *For Create Shipping
  const createShipping = async (formData) => {
    setLoading(true);
    try {
      if (vehicleDetail.length === 0)
        return ErrorToaster("Please select add vin");

      const vehicles = [];
      vehicleDetail.forEach((e) => {
        let newObj = {
          booking_id: e.id || e?.booking_id,
          vin: e.vin,
          customer_id: e.customer_id,
          towed_by: e?.towed_by,
          towing_currency: e?.tower?.currency,
        };

        vehicles.push(newObj);
      });

      let obj = {
        vehicles: vehicles,
        container_no: formData?.containerNo,
        booking_no: formData?.bookingRef || formData?.bookingNo,
        shipping_line: selectedShippingLine?.id,
        vendor_yard: formData?.vendorYard,
        container_size: selectedContainerSize?.id,
        destination: selectedDestination?.id,
        country_id: selectedCountry?.id,
        location_id: selectedLocation?.id,
        service_provider: null,
        shipping_vendor: selectedShippingVendor?.id,
        shipping_currency: selectedShippingVendor?.currency,
        clearer: selectedClearer?.id,
        clearance_currency: selectedClearer?.currency,
        towing_currency: selectedVehicleTower?.currency,
        galaxy_yard: selectedGalaxyYard ? selectedGalaxyYard?.id : null,
        loading_port_id: selectedLoadingPort?.id,
        loading_date: getYearMonthDateFormate(loadingDate),
        export_date: getYearMonthDateFormate(exportDate),
        eta: getYearMonthDateFormate(etaDate),
        arrived_port_date: getYearMonthDateFormate(arrivedDate),
        arrived_galaxy_date: getYearMonthDateFormate(arrivedGalaxyDate),
        picked_auction_date: getYearMonthDateFormate(auctionDate),
        notes: formData?.note,
        via: planDetail?.through_plan ? 'booking_ref' : "vin"
      };

      const { message } = await ShippingServices.createShipping(obj);
      SuccessToaster(message);
      navigate("/shipping-list");
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };

  // *For Handle Select Shipping Vendor and Filter Loading Port
  const handleSelectShippingVendor = (data) => {
    try {
      const filteredPort = loadingPort.filter(
        (e) => e?.country_id === data?.country_id
      );
      setSelectedShippingVendor(data);

      if (filteredPort.length === 0) {
        getLoadingPorts();
      } else {
        setLoadingPort(filteredPort);
      }
    } catch (error) {
      ErrorToaster(error);
    }
  };

  return (
    <Box sx={{ m: 4, p: 5, }}>
      <Typography variant="h5" sx={{ mb: 4 }}>Add Container</Typography>

      <Box sx={{ p: 3, bgcolor: "#ffff", borderRadius: 2, boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)" }}>
        <Grid container spacing={2} alignItems="center" sx={{ mb: 3 }}>

          <Grid item sm={4}>
            {/* <SelectField
              label={"VIN"}
              placeholder="Search Vin"

              options={shippingVins}
              selected={selectedShippingVin}
              onSelect={(value) => setSelectedShippingVin(value)}
              error={errors?.shippingVin?.message}
              register={register("shippingVin", {
                // required: "Please select VIN."
              })}
              disabled={disableVin}

            /> */}
            <InputField
              label={"VIN"}
              placeholder="Search Vin"

              // options={shippingVins}
              // selected={selectedShippingVin}
              // onSelect={(value) => setSelectedShippingVin(value)}
              error={errors?.shippingVin?.message}
              register={register("shippingVin", {
                onChange: () => { }
                // required: "Please select VIN.", 
              })}
              disabled={planDetail?.through_plan}

            />
          </Grid>
          <Grid item mt={2}>
            <PrimaryButton title="Add" type="submit" loading={vinLoading} onClick={() => { getVinDetails() }}
              disabled={planDetail?.through_plan}
              startIcon={<Add />} />
          </Grid>
          <Grid item sm={4}>
            <InputField
              label="Add Booking Ref."
              placeholder="Enter Booking Ref."
              // value={bookingRef}
              register={register("bookingRef", {
                // required: "Please select booking Reference No." 
              })}
              // onChange={(e) => setBookingRef(e.target.value)}
              disabled={disableBookingRef}

            />
          </Grid>
          <Grid item mt={2}>
            <PrimaryButton title="Search" type={'button'} onClick={() => { getBookingDetails() }}
              disabled={disableBookingRef}
            />
          </Grid>
        </Grid>
      </Box>

      {vehicleDetail.length > 0 && (
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableHead sx={{}}>
              <TableRow>
                <Cell sx={{ color: "white" }}>VIN</Cell>
                <Cell sx={{ color: "white" }}>Buyer ID</Cell>
                <Cell sx={{ color: "white" }}>Customer ID</Cell>
                <Cell sx={{ color: "white" }}>Customer</Cell>
                <Cell sx={{ color: "white" }}>Lot No.</Cell>
                <Cell sx={{ color: "white" }}>Country From</Cell>
                <Cell sx={{ color: "white" }}>Location</Cell>
                <Cell sx={{ color: "white" }}>
                  Actions
                  <IconButton onClick={() => setTableExpanded(!tableExpanded)}>
                    {tableExpanded ? <ExpandLess sx={{ color: "white" }} /> : <ExpandMore sx={{ color: "white" }} />}
                  </IconButton>
                </Cell>
              </TableRow>
            </TableHead>
            {/* <Collapse in={tableExpanded} timeout="auto" unmountOnExit> */}
            <TableBody>
              {vehicleDetail.map((item, index) => (
                <TableRow key={index}>
                  <Cell>{item?.vin}</Cell>
                  <Cell>{item?.buyer?.id}</Cell>
                  <Cell>{item?.customer?.id || item?.customer_id}</Cell>
                  <Cell>{item?.customer?.name || item?.customer_name}</Cell>
                  <Cell>{item?.lot_number}</Cell>
                  <Cell>{item?.country?.name || item?.country?.country_name}</Cell>
                  <Cell>{item?.location?.state_code}-{item?.location?.city_name}</Cell>
                  <Cell>
                    <IconButton onClick={() => setVehicleDetail(vehicleDetail.filter((_, i) => i !== index))}>
                      <Delete sx={{ color: "red" }} />
                    </IconButton>
                  </Cell>
                </TableRow>
              ))}
            </TableBody>
            {/* </Collapse> */}
          </Table>
        </TableContainer>
      )}
      <Box sx={{ mt: 2 }} component={"form"} onSubmit={handleSubmit(createShipping)}>
        <Box sx={{ p: 3, bgcolor: "#ffff", borderRadius: 2, boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", }}>
          {/* Additional Fields Below the Table */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}><InputField label="Container No" disabled={planDetail?.through_plan === false ? true : false} placeholder="Enter Container No" register={register("containerNo", {
              required: "Please select container No.",
            })} />

            </Grid>
            <Grid item xs={12} sm={4}>
              <InputField
                label={"Booking No"}
                placeholder={"Booking No"}
                error={errors?.bookingNo?.message}
                register={register("bookingNo", {
                  required: "Please enter a booking no.",
                })}
                disabled={planDetail?.through_plan === false ? true : false}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SelectField
                onSearch={(v) => getContainerSizes(v)}
                label={"Container Size"}
                options={containerSizes}
                selected={selectedContainerSize}
                onSelect={(value) => setSelectedContainerSize(value)}
                error={errors?.containerSize?.message}
                register={register("containerSize", {
                  required: "Please select container size.",
                })}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SelectField
                disabled={planDetail?.through_plan}
                onSearch={(v) => getShippingLines(v)}
                label={"Shipping Line"}
                options={shippingLines}
                selected={selectedShippingLine}
                onSelect={(value) => setSelectedShippingLine(value)}
                error={errors?.shippingLine?.message}
                register={register("shippingLine", {
                  required: "Please select shipping line.",
                })}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SelectField
                onSearch={(v) => getDestinations(v)}
                label={"Destination"}
                options={destinations}
                selected={selectedDestination}
                onSelect={(value) => setSelectedDestination(value)}
                error={errors?.destination?.message}
                register={register("destination", {
                  required: "Please select destination.",
                })}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SelectField
                disabled={planDetail?.through_plan}
                onSearch={(v) => getShippingVendors(v)}
                label={"Service Provider"}
                options={shippingVendors}
                selected={selectedShippingVendor}
                onSelect={(value) => handleSelectShippingVendor(value)}
                error={errors?.shippingVendor?.message}
                register={register("shippingVendor", {
                  required: "Please select shipping vendor.",
                })}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SelectField
                disabled={planDetail?.through_plan || !selectedShippingVendor}
                label={"Loading Port"}
                options={loadingPort}
                selected={selectedLoadingPort}
                onSelect={(value) => setSelectedLoadingPort(value)}
                error={errors?.loadingPort?.message}
                register={register("loadingPort", {
                  required: "Please select loading port.",
                })}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <SelectField
                onSearch={(v) => getClearers(v)}
                label={"Cleared By"}
                options={clearers}
                selected={selectedClearer}
                onSelect={(value) => setSelectedClearer(value)}
                error={errors?.clearer?.message}
                register={register("clearer")}
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ p: 3, bgcolor: "#ffff", borderRadius: 2, boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", mt: 2 }}>
          {/* Additional Fields Below the Table */}
          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item xs={12} sm={4}>
              <DatePicker
                size={'small'}
                label={"Loading Date"}
                value={loadingDate}
                register={register("loadingDate")}
                onChange={(date) => handleLoadingDate(date)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DatePicker
                size={'small'}
                label={"Export Date"}
                value={exportDate}
                register={register("exportDate")}
                onChange={(date) => handleExportDate(date)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DatePicker
                size={'small'}
                label={"ETA Date"}
                value={etaDate}
                register={register("etaDate")}
                onChange={(date) => handleEtaDate(date)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DatePicker
                size={'small'}
                label={"Arrived at port date"}
                value={arrivedDate}
                onChange={(date) => handleArrivedDate(date)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DatePicker
                size={'small'}
                label={"Arrived at galaxy yard date"}
                value={arrivedGalaxyDate}
                onChange={(date) => handleArrivedGalaxyDate(date)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SelectField
                disabled={arrivedGalaxyDate ? false : true}
                addNew={(newValue) => createGalaxyYard(newValue)}
                onSearch={(v) => getGalaxyYards(v)}
                label={"Galaxy Yard"}
                options={galaxyYards}
                selected={selectedGalaxyYard}
                onSelect={(value) => setSelectedGalaxyYard(value)}
                register={register("galaxyYard")}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputField
                label={"Note"}
                placeholder={"Note"}
                register={register("note")}
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 4, textAlign: "right" }}>
          <PrimaryButton title="submit" type="submit" loading={loading} />
        </Box>
      </Box>
    </Box>
  );
}

export default CreateShipping;
