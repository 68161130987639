import { FormControl, TextField, Typography } from "@mui/material";
import { Fragment } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Colors from "assets/Style/Colors";

function DatePicker({
  label,
  value,
  size,
  views,
  openTo,
  error,
  disabled,
  disablePast,
  disableFuture,
  register,
  onChange,
  minDate,
  maxDate,
  bgcolor,
  padding,
}) {
  return (
    <Fragment>
      <Typography
        variant="body1"
        sx={{ textTransform: "capitalize", color: Colors.charcoalGrey }}
      >
        {label}
      </Typography>
      <FormControl
        variant="standard"
        fullWidth
        sx={{ mb: 1, ".MuiStack-root": { overflow: "hidden" } }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DemoContainer
            sx={{
              ".css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                padding: padding ? padding : "17px 14px",
              },
            }}
            components={["DesktopDatePicker"]}
          >
            <DesktopDatePicker
              openTo={openTo}
              views={views}
              disabled={disabled}
              disablePast={disablePast}
              disableFuture={disableFuture}
              value={value}
              minDate={minDate}
              maxDate={maxDate}
              sx={{ width: 1, backgroundColor: bgcolor,
                "& .MuiOutlinedInput-root": {
                    borderRadius: 3,
                }, }}
              onChange={onChange}
              slotProps={{
                textField: { size: size , },
                field: { clearable: true },
              }}
              renderInput={(params) => (
                <TextField {...params} {...register} error={error} />
              )}
            />
          </DemoContainer>
        </LocalizationProvider>
        {error && (
          <Typography color="error" sx={{ fontSize: 12, textAlign: "left" }}>
            {error}
          </Typography>
        )}
      </FormControl>
    </Fragment>
  );
}

export default DatePicker;
