import axios from 'axios';
import CryptoJS from 'crypto-js';
const secretKey = 'GHG@#XX212311XWNHHH$665NG';

function encryptData(data) {
  const jsonString = JSON.stringify(data);
  const encrypted = CryptoJS.AES.encrypt(jsonString, secretKey).toString();
  return encrypted;
}

function decryptData(encryptedData) {
  const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
  const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedString);
}

export const baseUrl = process.env.REACT_APP_BASE_URL;

const instance = axios.create({
  baseURL: baseUrl + '/api/',
  // baseURL: 'https://api.galaxyshipping.com/api/',
  withCredentials: true,
});

instance.interceptors.request.use((request) => {
  let user = JSON.parse(localStorage.getItem('user'));
  const sessionID = JSON.parse(localStorage.getItem('sessionId'));

  const contentType = request.headers['Content-Type'] || request.headers['content-type'];
  if (String(contentType).includes('application/json')) {
    // request.data = {
    //   data: encryptData(request.data)
    // };
  }

  request.headers = {
    Accept: 'application/json, text/plain, */*',
    Authorization: `Bearer ${user?.token}`,
    sessionId: sessionID,
    timezone: new Date().getTimezoneOffset(),
    route: window.location.pathname,
  };

  return request;
});

instance.interceptors.response.use(
  (response) => {
    if (response) {
      // response.data = JSON.parse(decryptData(response.data));
      return response;
    }
  },
  (error) => Promise.reject(error)
);

export default instance;
